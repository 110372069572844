import { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useLocation } from 'react-router'
import qs from 'qs'
import { Config } from '../../../config'

// ~~~~~~ Component

export const RedirectionsLoginWithRedirectPage = () => {
  // ~~~~~~ Hooks

  const location = useLocation()

  const redirectPath = qs.parse(location.search, { ignoreQueryPrefix: true }).path

  const { loginWithRedirect } = useAuth0()

  // ~~~~~~ Effects

  useEffect(() => {
    !Config.AllBlocked &&
      loginWithRedirect({
        authorizationParams: {
          redirect_uri:
            typeof redirectPath === 'string' ? `${Config.Origin}${redirectPath}` : undefined,
        },
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // ~~~~~~ Render

  return null
}
