import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { Trans } from '../../components/common/intl/trans'
import { LazySVG } from '../../components/svgs/lazy-svg/component'
import { Config } from '../../config'
import { ModalsIds } from '../../constants/modals'
import { Routes } from '../../constants/routes/routes'
import { useModelRootUpdateUserkind } from '../../hooks/model/root/use-model-root-update-userkind'
import { StoreState } from '../../models/app/model'
import { RootModelFieldsMR } from '../../models/bcast/root/model'
import { ExtensionService } from '../../services/extension'
import { ModalsMC } from '../../store/actions-mutators/modals/mutators'
import { ApiBcastUserSettingsAC } from '../../store/actions/api-bcast/user-settings/actions'
import { UtilsLog } from '../../utils/logs'
import { ClassName, STStartingABroadcastPage } from './style'
import { BcastTrackEvents } from '../../services/track-events'
import { useTrackUserContext } from '../../hooks/track-events/use-track-user-context'
import { useTrackStreamContext } from '../../hooks/track-events/use-track-stream-context'
import { useTrackScreenStreamContext } from '../../hooks/track-events/use-track-screen-stream-context'

// ~~~~~~ Constants

const TimeToShowNotReveivingData = 3000

const Logo = LazySVG('logos/broadcast-square')

// ~~~~~~ Component

export const StartingABroadcastPage = () => {
  // ~~~~~~ Hooks

  useModelRootUpdateUserkind('bcaster')

  const navigate = useNavigate()

  const dispatch = useDispatch()

  const userContext = useTrackUserContext()

  const streamContext = useTrackStreamContext()

  const screenStreamContext = useTrackScreenStreamContext('pub')

  // ~~~~~~ State

  const [showNoLastEvent, setShowNoLastEvent] = useState(false)

  const { broadcastId } = useSelector((state: StoreState) => state.streamManagerServerPubMain)

  const { lastEvent, isReady } = useSelector((state: StoreState) => state.streamManagerPubMain)

  const { shouldExit, reason: exitReason } = useSelector((state: StoreState) => state.exit)

  const currentUser = useSelector((state: StoreState) => state.currentUser)

  const userSettings = useSelector((state: StoreState) => state.userSettings)

  const subscription = useSelector((state: StoreState) => state.subscription)

  const { redirectPath } = useSelector((state: StoreState) => state.root)

  const dnBcast = useSelector((state: StoreState) => state.dnBcast)

  // ~~~~~~ Effects

  // - Page event

  useEffect(() => {
    BcastTrackEvents.calls.Page.view('/starting')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // - Set redirect path

  useEffect(() => {
    dispatch(RootModelFieldsMR.redirectPath.MC.change(Routes.Start))
  }, [dispatch])

  // - Show disconnected by fail dialog

  useEffect(() => {
    if (!shouldExit) return

    switch (exitReason) {
      case 'conn-closed':
      case 'publish-fail':
      case 'data-channel-error':
        dispatch(ModalsMC.open(ModalsIds.ConfirmViewerDisconnectedByConnFail))
        break

      case 'network-offline':
        dispatch(ModalsMC.open(ModalsIds.ConfirmBroadcasterDisconectedByFail))
        break

      case 'user-manual-exit':
      default:
        dispatch(ModalsMC.open(ModalsIds.ConfirmViewerDisconnectedByConnFail))
    }

    //
  }, [dispatch, exitReason, shouldExit])

  // - Redirect to Started to see exit reason

  useEffect(() => {
    if (!shouldExit) return

    UtilsLog.devLog(
      String.fromCodePoint(128256),
      '/starting',
      'should exit redirect to',
      '/started',
    )

    navigate(Routes.Started, { replace: true })

    //
  }, [navigate, shouldExit])

  // - Redirect to main if no id (red5pro)

  useEffect(() => {
    if (Config.Features.BcastService2) return

    if (broadcastId) return

    UtilsLog.devLog(String.fromCodePoint(128256), '/starting', 'no id redirect to', redirectPath)

    navigate(redirectPath, { replace: true })

    //
  }, [navigate, broadcastId, redirectPath])

  // - Redirect to main if no id (livekit)

  useEffect(() => {
    if (!Config.Features.BcastService2) return

    if (dnBcast.bcastId) return

    UtilsLog.devLog(String.fromCodePoint(128256), '/starting', 'no id redirect to', redirectPath)

    navigate(redirectPath, { replace: true })

    //
  }, [dnBcast.bcastId, navigate, redirectPath])

  // - Redirect to Broadcasting if connection has ended successfully (red5pro)

  useEffect(() => {
    if (Config.Features.BcastService2) return

    if (!broadcastId || !isReady) return

    UtilsLog.devLog(String.fromCodePoint(128256), '/starting', 'all ok redirect to', '/started')

    navigate(Routes.Started, { replace: true })

    !Config.isExtension && ExtensionService.SendMessage.ToExtension.broadcastingStateChanged()

    // Onboarding "create a bcast"

    if (userSettings.isOnboardingRunning && !userSettings.onboarding_create_a_bcast) {
      dispatch(
        ApiBcastUserSettingsAC.update(currentUser.id, {
          onboarding_create_a_bcast: true,
        }),
      )

      // Track

      BcastTrackEvents.calls.Onboarding['1-onboarding-create-a-bcast'](userContext)
    }

    // Track

    BcastTrackEvents.calls.Streams.PubMain.stream(userContext, streamContext)

    //
  }, [
    currentUser,
    navigate,
    broadcastId,
    subscription,
    dispatch,
    isReady,
    userSettings.isOnboardingRunning,
    userSettings.onboarding_create_a_bcast,
    userContext,
    streamContext,
  ])

  // - Redirect to Broadcasting if connection has ended successfully (livekit)

  useEffect(() => {
    if (!Config.Features.BcastService2) return

    if (!dnBcast.roomIsConnected) return

    UtilsLog.devLog(String.fromCodePoint(128256), '/starting', 'all ok redirect to', '/started')

    navigate(Routes.Started, { replace: true })

    !Config.isExtension && ExtensionService.SendMessage.ToExtension.broadcastingStateChanged()

    // Onboarding "create a bcast"

    if (userSettings.isOnboardingRunning && !userSettings.onboarding_create_a_bcast) {
      dispatch(
        ApiBcastUserSettingsAC.update(currentUser.id, {
          onboarding_create_a_bcast: true,
        }),
      )

      // Track

      BcastTrackEvents.calls.Onboarding['1-onboarding-create-a-bcast'](userContext)
    }

    // Track

    BcastTrackEvents.calls.Streams.PubMain.stream(userContext, screenStreamContext)

    //
  }, [
    currentUser.id,
    dispatch,
    dnBcast.roomIsConnected,
    navigate,
    screenStreamContext,
    userContext,
    userSettings.isOnboardingRunning,
    userSettings.onboarding_create_a_bcast,
  ])

  // - Show not receiving data messaage

  useEffect(() => {
    if (Config.Features.BcastService2) return

    if (lastEvent !== 'Empty') return

    const time = setTimeout(() => {
      setShowNoLastEvent(true)
    }, TimeToShowNotReveivingData)

    return () => {
      clearTimeout(time)
    }
  }, [lastEvent])

  // - Hide not receiving data message

  useEffect(() => {
    if (Config.Features.BcastService2) return

    if (lastEvent === 'Empty' || !showNoLastEvent) return

    setShowNoLastEvent(false)

    //
  }, [lastEvent, showNoLastEvent])

  // ~~~~~~ Render

  return (
    <STStartingABroadcastPage>
      <div className={`${ClassName}--center`}>
        {/* Logo */}

        <div className={`${ClassName}--center--logo`}>
          <Logo size={64} />
        </div>

        {/* Creating your broadcast... */}

        <div className={`${ClassName}--center--creating`}>
          <Trans id="pages.starting.Title" />
        </div>

        {/* Start events */}

        <div className={`${ClassName}--center--events`}>
          <Trans id={`events.pub.${lastEvent}`} />
        </div>

        {/* Focus the source is required */}

        {showNoLastEvent ? (
          <div className="no-last-event">
            <Trans id="pages.starting.NoLastEvent" />
          </div>
        ) : undefined}
      </div>

      {/* - */}
    </STStartingABroadcastPage>
  )
}
