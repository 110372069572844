import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { StoreState } from '../../../models/app/model'
import { Footer } from '../../common/footer/component'
import { LazySVG } from '../../svgs/lazy-svg/component'
import { LayoutCup } from '../cup/component'
import { LayoutCupContent } from '../cup/parts/content/component'
import { LayoutCupFooter } from '../cup/parts/footer/component'
import { LayoutCupTopLeft } from '../cup/parts/top/left/component'
import { STLayoutText, STLayoutTextTopLeft } from './style'

// ~~~~~~ Constants

const BackIcon = LazySVG('icons/arrow-back')

// ~~~~~~ Props

type Props = {
  children: React.ReactNode
}

// ~~~~~~ Component

export const LayoutText: React.FC<Props> = ({ children }) => {
  // ~~~~~~ Hooks

  const navigate = useNavigate()

  // ~~~~~~ State

  const { redirectPath } = useSelector((state: StoreState) => state.root)

  // ~~~~~~ Handlers

  function onClickBack() {
    navigate(redirectPath, { replace: true })
  }

  // ~~~~~~ Render

  return (
    <div>
      <LayoutCup $forceThemeInTop="dark" $forceThemeInFooter="light">
        {/* Top left */}

        <LayoutCupTopLeft>
          <STLayoutTextTopLeft>
            <div onClick={onClickBack} className="layout-text-top-left-back">
              <BackIcon size={20} />
            </div>
          </STLayoutTextTopLeft>
        </LayoutCupTopLeft>

        {/* Content */}

        <LayoutCupContent>
          <STLayoutText>
            <div className="layout-text-header-and-content">
              <div className="layout-text-content">{children}</div>
            </div>
          </STLayoutText>
        </LayoutCupContent>

        {/* Footer */}

        <LayoutCupFooter>
          <Footer forceTheme="light" />
        </LayoutCupFooter>

        {/*  */}
      </LayoutCup>
    </div>
  )
}
