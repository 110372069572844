import { useParams } from 'react-router'
import { BroadcastIdRouteParams } from '../../../../../../constants/routes/routes'
import { STJoinedCommonHeaderTopRightBroadcastId } from './style'

// ~~~~~~ Component

export const JoinedCommonHeaderTopRightBroadcastId = () => {
  // ~~~~~~ State

  const { broadcastId } = useParams<BroadcastIdRouteParams>()

  // ~~~~~~ Render

  return (
    <STJoinedCommonHeaderTopRightBroadcastId>
      ID: {broadcastId}
    </STJoinedCommonHeaderTopRightBroadcastId>
  )
}
