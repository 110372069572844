import { AnimatePresence, motion } from 'framer-motion'
import { FirstPortal } from '../../../portals/debug-portal'
import { Button } from '../button/component'
import { Trans } from '../intl/trans'
import { useNewVersionAvailable } from './hook'
import { STNewVersionAvailable } from './style'

// ~~~~~~ Constants

const initialX = 100

// ~~~~~~ Component

export const NewVersionAvailable = () => {
  // ~~~~~~ Hooks

  const { newVersionAvailable, updateSW, updatingSW, dismiss } = useNewVersionAvailable()

  // ~~~~~~ Render

  return (
    <FirstPortal>
      <STNewVersionAvailable>
        <AnimatePresence>
          {newVersionAvailable ? (
            <motion.div
              transition={{
                type: 'tween',
              }}
              initial={{
                y: -initialX,
              }}
              animate={{
                y: 0,
              }}
              exit={{
                y: -initialX,
              }}
            >
              <div className="panel">
                <div className="message">
                  <Trans id="common.NewVersion" />
                </div>

                <div className="actions">
                  <Button
                    $colorType="primary"
                    $size="s"
                    intlId="common.Update"
                    $active={!updatingSW}
                    $running={updatingSW}
                    disabled={updatingSW}
                    onClick={updateSW}
                  />

                  <Button
                    $colorType="secondary"
                    $size="s"
                    intlId="common.Dismiss"
                    $active={!updatingSW}
                    $running={false}
                    disabled={updatingSW}
                    onClick={dismiss}
                  />
                </div>
              </div>
            </motion.div>
          ) : undefined}
        </AnimatePresence>
      </STNewVersionAvailable>
    </FirstPortal>
  )
}
