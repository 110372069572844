import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Scrollbars from 'react-custom-scrollbars'
import { HooksData } from '@dn/hooks'

import { Button } from '../../../../../components/common/button/component'
import { Trans } from '../../../../../components/common/intl/trans'
import { SliderCheck } from '../../../../../components/common/slider-check/component'
import { ModalsIds } from '../../../../../constants/modals'
import { useValidator } from '../../../../../hooks/validators/use-validators'
import { StoreState } from '../../../../../models/app/model'
import {
  GoogleSlidesCreateFieldsMR,
  GoogleSlidesModel,
} from '../../../../../models/bcast/google-presentations/model'
import { UiLayoutPanelsFieldsMR } from '../../../../../models/bcast/ui-layout-panels/model'
import { ModalsMC } from '../../../../../store/actions-mutators/modals/mutators'
import { ApiBcastGoogleSlidesAC } from '../../../../../store/actions/api-bcast/google-slides/actions'
import { GoogleAuth } from '../../../../../third-parties/google-api/library'
import { LazySVG } from '../../../../../components/svgs/lazy-svg/component'
import { ClassName, ItemHeight, Padding, STStartedLeftPanelSharePaintSlides } from './style'
import { IntlInput } from '../../../../../components/common/intl-input/component'
import { useSlidesScrollbarHeight } from './behaviour-logic/use-slides-scrollbar-height'
import { Config } from '../../../../../config'
import { CurrentUserEditFieldsMR } from '../../../../../models/bcast/current-user/model'
import { useTrackStreamContext } from '../../../../../hooks/track-events/use-track-stream-context'
import { useTrackUserContext } from '../../../../../hooks/track-events/use-track-user-context'
import { BcastTrackEvents } from '../../../../../services/track-events'
import { useBcasterGConnect } from '../../../../../hooks/bcaster-g-connect/use-bcaster-g-connect'

// ~~~~~~ Constants

const ItemIcon = LazySVG('icons/file')

const NoProject: Project = {
  id: '',
  title: '',
}

// ~~~~~~ Types

type Project = {
  id: string
  title: string
}

type Props = {
  panelElementRef: React.RefObject<HTMLDivElement | null>
  panelMargin: number

  topElementRef: React.RefObject<HTMLDivElement | null>

  classroomElementRef: React.RefObject<HTMLDivElement | null>

  exitElementRef: React.RefObject<HTMLDivElement | null>
}

// ~~~~~~ Component

export const StartedLeftPanelSharePaintSlides: React.FC<Props> = ({
  panelElementRef,
  panelMargin,
  topElementRef,
  classroomElementRef,
  exitElementRef,
}) => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  const validateTitle = useValidator(
    GoogleSlidesModel.validations.Title,
    GoogleSlidesCreateFieldsMR.title,
  )

  const slidesScroll = useSlidesScrollbarHeight(
    panelElementRef,
    topElementRef,
    classroomElementRef,
    exitElementRef,
    panelMargin,
    Padding,
    ItemHeight,
  )

  const userContext = useTrackUserContext()

  const streamContext = useTrackStreamContext()

  const bcasterGConnect = useBcasterGConnect()

  // ~~~~~~ State

  // - Local

  const [shareView, setShareView] = useState<'create' | 'select'>('create')

  const [generatedTitle, setGeneratedTitle] = useState('')

  const [isDirty, setIsDirty] = useState(false)

  const [selectedProject, setSelectedProject] = useState(NoProject)

  // - Store

  const currentUser = useSelector((state: StoreState) => state.currentUser)

  const layoutPanels = useSelector((state: StoreState) => state.uiLayoutPanels)

  const providerGoogle = useSelector((state: StoreState) => state.providerGoogle)

  const slidesMetaList = useSelector((state: StoreState) => state.googleSlidesPresentationMetaList)

  const addImageToSlide = useSelector((state: StoreState) => state.googleSlidesAddImageToSlide)

  const { title, title_err } = useSelector(
    (state: StoreState) => state.googleSlidesCreatePresentation,
  )

  const imageToShare = useSelector((state: StoreState) => state.imageToShare)

  // ~~~~~~ Computed

  const shareIsOpen = layoutPanels.shareImageToSlidesState === 'open'

  const userCanShareImage =
    (providerGoogle.slides && providerGoogle.drive) ||
    (Config.isExtension && !!currentUser.googleAccessToken)

  const slidesQuantity = slidesMetaList.list.length

  // const getListIsRunning = slidesMetaList.uiStatus === 'running'

  const shareImageIsRunning = addImageToSlide.uiStatus === 'running'

  const alreadyShared = !!currentUser.lastSelectedGoogleSlide.id

  const showEmptyList = slidesMetaList.list.length === 0 && slidesMetaList.uiStatus !== 'running'

  const titleChangedByUser = title !== generatedTitle

  const imageToShareName = imageToShare.name

  const withGoogleAccessToken = useMemo(
    () =>
      Config.isExtension && !currentUser.id
        ? ({ withIt: true, accessToken: currentUser.googleAccessToken } as const)
        : ({ withIt: false } as const),
    [currentUser.googleAccessToken, currentUser.id],
  )

  // ~~~~~~ State dependent Hooks

  const prevUserCanShareImage = HooksData.PrevValue.useHook(userCanShareImage)

  // ~~~~~~ Handlers

  function onChangeSlider() {
    if (!userCanShareImage) {
      dispatch(UiLayoutPanelsFieldsMR.onLinkWithGoogleShouldOpen.MC.change('slides'))

      if (Config.isExtension) {
        currentUser.id
          ? dispatch(ModalsMC.open(ModalsIds.LinkGoogleInBcast))
          : dispatch(
              ModalsMC.open(ModalsIds.GoogleSignIn, () => {
                GoogleAuth.login({
                  onSuccess: ({ access_token, expires_at }) => {
                    // Update info

                    dispatch(CurrentUserEditFieldsMR.googleAccessToken.MC.change(access_token))
                    dispatch(
                      CurrentUserEditFieldsMR.googleAccessTokenExpiresAt.MC.change(expires_at),
                    )

                    // Open GSlides panel

                    dispatch(UiLayoutPanelsFieldsMR.shareImageToSlidesState.MC.change('open'))
                  },
                })
              }),
            )

        return
      }

      bcasterGConnect()

      return
    }

    // Model

    dispatch(
      UiLayoutPanelsFieldsMR.shareImageToSlidesState.MC.change(shareIsOpen ? 'closed' : 'open'),
    )

    // Changed to close

    if (shareIsOpen) {
      setSelectedProject(NoProject)

      return
    }

    // Changed to open

    dispatch(ApiBcastGoogleSlidesAC.listPresentations(withGoogleAccessToken))

    // Required to recalculate scrollbar height on change to "checked"
    // as the internal size of the card changes.
    // We need the space used by "Available slides"

    setTimeout(() => slidesScroll.recalculateScrollbarHeight(), 0)
  }

  function onClickNavigate(value: 'create' | 'select') {
    setShareView(value)

    // Required to calculate scrollbar height

    setTimeout(() => slidesScroll.recalculateScrollbarHeight(), 0)
  }

  // - For create slide and share

  function onChangeTitle(value: string) {
    isDirty && validateTitle(value)

    dispatch(GoogleSlidesCreateFieldsMR.title.MC.change(value))
  }

  function onShareImageCreatingSlide() {
    setIsDirty(true)

    // Validate

    const errors = [validateTitle(title).length].filter(Boolean)

    // Cancel submit

    if (errors.length) return

    // Clean dirty

    setIsDirty(false)

    // Submit

    dispatch(
      ApiBcastGoogleSlidesAC.createAndAddImageSlide(
        withGoogleAccessToken,

        { title },

        { image_content: imageToShare.file },
      ),
    )

    // Track

    BcastTrackEvents.calls.Google.Bcaster.shareSlideImage(userContext, streamContext)
  }

  // - For select and share

  function onClickItem(project: Project) {
    if (shareImageIsRunning) return

    setSelectedProject(project)
  }

  function onShareImageInSelectedSlide() {
    const project = currentUser.lastSelectedGoogleSlide.id
      ? currentUser.lastSelectedGoogleSlide
      : selectedProject

    if (shareImageIsRunning || !project.id) return

    dispatch(
      ApiBcastGoogleSlidesAC.addImageSlide(withGoogleAccessToken, project, {
        image_content: imageToShare.file,
      }),
    )

    // Track

    BcastTrackEvents.calls.Google.Bcaster.shareSlideImage(userContext, streamContext)
  }

  // ~~~~~~ Effects

  // - On connect account, get slides list

  useEffect(() => {
    if (prevUserCanShareImage !== false || userCanShareImage !== true) return

    dispatch(ApiBcastGoogleSlidesAC.listPresentations(withGoogleAccessToken))

    //
  }, [dispatch, prevUserCanShareImage, userCanShareImage, withGoogleAccessToken])

  // - On mount, if account is connected, get slides list

  useEffect(() => {
    if (!userCanShareImage) return

    dispatch(ApiBcastGoogleSlidesAC.listPresentations(withGoogleAccessToken))

    // We don't want update value side effect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // - Set slide title from image to share name

  useEffect(() => {
    if (titleChangedByUser || alreadyShared) return

    dispatch(GoogleSlidesCreateFieldsMR.title.MC.change(imageToShareName))

    setGeneratedTitle(imageToShareName)

    //
  }, [alreadyShared, dispatch, imageToShareName, shareView, titleChangedByUser])

  // ~~~~~~ Render

  return (
    <STStartedLeftPanelSharePaintSlides>
      {/* Title and slider */}

      <div ref={slidesScroll.refs.titleRef} className={`${ClassName}--title-slider`}>
        <div className={`${ClassName}--title-slider--title`}>Google Slides</div>

        <div className={`${ClassName}--title-slider--title`}>
          <SliderCheck
            $colorType="secondary"
            checked={userCanShareImage ? shareIsOpen : false}
            onChange={onChangeSlider}
          />
        </div>
      </div>

      {/* Closed info */}

      {!shareIsOpen && !userCanShareImage ? (
        <div className={`${ClassName}--closed-info`}>
          <Trans id="pages.started.left-panel.share-paint.slides.Info" />
        </div>
      ) : undefined}

      {/* Already shared */}

      {shareIsOpen && alreadyShared ? (
        <div className={`${ClassName}--already-shared`}>
          {/* Info */}

          <div className={`${ClassName}--already-shared--info`}>
            <Trans id="pages.started.left-panel.share-paint.slides.Current" />
          </div>

          {/* Selected slide */}

          <div className={`${ClassName}--already-shared--selected`}>
            {/* Icon */}

            <div className={`${ClassName}--already-shared--selected--icon`}>
              <ItemIcon size={24} />
            </div>

            {/* Slide */}

            <div className={`${ClassName}--already-shared--selected--name`}>
              {currentUser.lastSelectedGoogleSlide.title}
            </div>
          </div>

          {/* Action */}

          <div className={`${ClassName}--already-shared--action`}>
            <Button
              $colorType="primary"
              intlId="pages.started.left-panel.share-paint.slides.actions.Select"
              $width="100%"
              disabled={shareImageIsRunning}
              $running={shareImageIsRunning}
              $active={!shareImageIsRunning}
              onClick={onShareImageInSelectedSlide}
            />
          </div>
        </div>
      ) : undefined}

      {/* Create new project */}

      {shareIsOpen && !alreadyShared && shareView === 'create' ? (
        <div className={`${ClassName}--create`}>
          {/* Info: This can hide the "close" button */}

          {/* <div className={`${ClassName}--create--info`}>
            <Trans id="pages.started.left-panel.share-paint.slides.InfoOpen" />
          </div> */}

          {/* Project name */}

          <IntlInput
            className={`${ClassName}--create--input`}
            value={title}
            onChange={onChangeTitle}
            errors={title_err}
            $disabled={shareImageIsRunning}
          />

          {/* Action */}

          <div ref={slidesScroll.refs.selectActionRef} className={`${ClassName}--create--action`}>
            <Button
              $colorType="primary"
              intlId="pages.started.left-panel.share-paint.slides.actions.Create"
              $width="100%"
              disabled={shareImageIsRunning || !title}
              $running={shareImageIsRunning}
              $active={!shareImageIsRunning && !!title}
              onClick={onShareImageCreatingSlide}
            />
          </div>

          {/* Nav: Select existing */}

          <div className={`${ClassName}--create--nav`} onClick={() => onClickNavigate('select')}>
            <Trans id="pages.started.left-panel.share-paint.slides.actions.GoToSelect" />
          </div>
        </div>
      ) : undefined}

      {/* Select from project list */}

      {shareIsOpen && !alreadyShared && shareView === 'select' ? (
        <div className={`${ClassName}--slides-select`}>
          {/* Available Info */}

          <div
            ref={slidesScroll.refs.slidesSelectInfoRef}
            className={`${ClassName}--slides-select--info`}
          >
            {/* Available slides */}

            {!showEmptyList ? (
              <div className={`${ClassName}--slides-select--info--quantity`}>
                <div>
                  <Trans id="pages.started.left-panel.share-paint.slides.Available" />
                </div>

                <div className={`${ClassName}--slides-select--info--quantity--data`}>
                  {slidesQuantity}
                </div>
              </div>
            ) : (
              <div className={`${ClassName}--slides-select--info--quantity`} />
            )}

            {/* Nav: Create new */}

            <div
              onClick={() => onClickNavigate('create')}
              className={`${ClassName}--slides-select--info--nav`}
            >
              <span>+</span>
              <span> </span>
              <Trans id="common.NewShe" />
            </div>
          </div>

          {/* List */}

          <Scrollbars
            universal
            autoHide={false}
            style={{ height: slidesScroll.scrollbarHeight }}
            renderTrackVertical={(props) => <div {...props} className="scrollbar-vertical" />}
          >
            <div className={`${ClassName}--slides-select--list`}>
              {/* - */}

              {/* Empty List */}

              {showEmptyList ? (
                <div className={`${ClassName}--slides-select--list--empty`}>
                  {/* Icon */}

                  <div className={`${ClassName}--slides-select--list--empty--icon`}>
                    <ItemIcon size={24} />
                  </div>

                  {/* Text */}

                  <div className={`${ClassName}--slides-select--list--empty--text`}>
                    <Trans id="pages.started.left-panel.share-paint.slides.EmptyList" />
                  </div>
                </div>
              ) : undefined}

              {/* List with data */}

              {!showEmptyList &&
                slidesMetaList.list.map((project, idx) => (
                  <div
                    key={project.id}
                    //
                    className={`${ClassName}--slides-select--list--item ${
                      idx === slidesMetaList.list.length - 1 ? 'last' : ''
                    } ${project === selectedProject ? 'selected' : ''}`}
                    //
                    onClick={() => onClickItem(project)}
                  >
                    {/* Icon */}

                    <div className={`${ClassName}--slides-select--list--item--icon`}>
                      <ItemIcon size={24} />
                    </div>

                    {/* Project (G.Slide) */}

                    <div className={`${ClassName}--slides-select--list--item--name`}>
                      {project.title}
                    </div>
                  </div>
                ))}

              {/* - */}
            </div>
          </Scrollbars>

          {/* Action */}

          {!showEmptyList ? (
            <div
              ref={slidesScroll.refs.selectActionRef}
              className={`${ClassName}--slides-select--action`}
            >
              <Button
                $colorType="primary"
                intlId="pages.started.left-panel.share-paint.slides.actions.Select"
                $width="100%"
                disabled={shareImageIsRunning || !selectedProject.id}
                $running={shareImageIsRunning}
                $active={!shareImageIsRunning && !!selectedProject.id}
                onClick={onShareImageInSelectedSlide}
              />
            </div>
          ) : undefined}
        </div>
      ) : undefined}

      {/* - */}
    </STStartedLeftPanelSharePaintSlides>
  )
}
