import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { Button } from '../../components/common/button/component'
import { Footer } from '../../components/common/footer/component'
import { InfoBoxDeviceNotSupported } from '../../components/common/infos/box/device-not-supported/component'
import { Trans } from '../../components/common/intl/trans'
import { ToastUtil } from '../../components/common/toast/util'
import { LayoutCup } from '../../components/layouts/cup/component'
import { LayoutCupContent } from '../../components/layouts/cup/parts/content/component'
import { LayoutCupFooter } from '../../components/layouts/cup/parts/footer/component'
import { LayoutCupTopRight } from '../../components/layouts/cup/parts/top/right/component'
import { ModalsIds } from '../../constants/modals'
import { Routes } from '../../constants/routes/routes'
import { useCustomAuth } from '../../hooks/auth/use-custom-auth'
import { StoreState } from '../../models/app/model'
import { RootModelFieldsMR } from '../../models/bcast/root/model'
import { ModalsMC } from '../../store/actions-mutators/modals/mutators'
import { ClassName, STSignInContent, STSignInPage } from './style'
import { SignInPageTopRight } from './top/right/component'
import { BcastTrackEvents } from '../../services/track-events'
import { BroadcastBrand } from '../../components/svgs/logos/broadcast-brand/component'

// ~~~~~~ Component

export const SignInPage = () => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  const { login, isLoading } = useCustomAuth()

  const navigate = useNavigate()

  // ~~~~~~ State

  const {
    email: currentUserEmail,
    name: curUserName,
    uiStatus: curUserUiStatus,
  } = useSelector((state: StoreState) => state.currentUser)

  // ~~~~~~ Computed

  const loginIsRunning = isLoading || curUserUiStatus === 'running'

  // ~~~~~~ Handlers

  function onClickSignIn() {
    login()
  }

  function onClickSignUp() {
    dispatch(ModalsMC.open(ModalsIds.CreateAnAccountFreeOrBuy))
  }

  // ~~~~~~ Effect

  // - Page event

  useEffect(() => {
    BcastTrackEvents.calls.Page.view('/sign-in')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // - Set new redirect path

  useEffect(() => {
    dispatch(RootModelFieldsMR.redirectPath.MC.change(Routes.SignIn))
  }, [dispatch])

  // - If current user id (logged) navigate to /start

  useEffect(() => {
    if (!currentUserEmail) return

    navigate(Routes.Start, { replace: true })

    ToastUtil.basic({
      kind: 'success',
      intlIds: [
        {
          id: 'Welcome',
          values: { value: curUserName || '' },
        },
      ],
    })

    //
  }, [currentUserEmail, curUserName, navigate])

  // ~~~~~~ Render

  return (
    <STSignInPage data-testid="sign-in-page">
      <LayoutCup>
        {/* Top */}

        {/* - Right */}

        <LayoutCupTopRight>
          <SignInPageTopRight />
        </LayoutCupTopRight>

        {/* Content */}

        <LayoutCupContent>
          <STSignInContent>
            <div className={`${ClassName}--sign-in-content`}>
              {/* Broadcast */}

              <div className={`${ClassName}--sign-in-content--sign-in-bcast`}>
                <BroadcastBrand />
              </div>

              {/* Info */}

              <div className={`${ClassName}--sign-in-content--sign-in-info`}>
                <Trans id="pages.sign-in.Info" />
              </div>

              {/* Device not supported */}

              <InfoBoxDeviceNotSupported
                className={`${ClassName}--sign-in-content--device-not-supported`}
              />

              {/* Sign in Button */}

              <Button
                data-testid="sign-in-act"
                className={`${ClassName}--sign-in-content--sign-in-action`}
                $width="100%"
                $colorType="primary"
                intlId="pages.sign-in.actions.SignIn"
                $active={!loginIsRunning}
                $running={loginIsRunning}
                disabled={loginIsRunning}
                onClick={onClickSignIn}
              />

              {/* Sign up Button */}

              <Button
                data-testid="sign-up-act"
                $width="100%"
                $colorType="tertiary-ghost"
                intlId="pages.sign-in.actions.SignUp"
                $active={true}
                $running={false}
                disabled={loginIsRunning}
                onClick={onClickSignUp}
              />
            </div>
          </STSignInContent>
        </LayoutCupContent>

        {/* Footer */}

        <LayoutCupFooter>
          <Footer />
        </LayoutCupFooter>

        {/*  */}
      </LayoutCup>
    </STSignInPage>
  )
}
