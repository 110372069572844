import { BcastConnectEvents } from '@dn/bcast/dist/types/typing/partial-service'
import { StateObservable, ofType } from 'redux-observable'
import { EMPTY, Observable, filter, mergeMap, of } from 'rxjs'
import { ServiceBcast } from '@dn/bcast'
import { StoreState } from '../../../../../models/app/model'
import { Config } from '../../../../../config'
import { ConstBcast } from '../../../../../constants/bcast'
import { CountdownBcastDurationAC } from '../../../../actions/countdown/bcast-duration/actions'

type Action = Extract<BcastConnectEvents, { type: '@dn/Bcast-conn-connected' }>

export const chainingPubMainEventsFromRoomPubConnectedToStartCountdown$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType('@dn/Bcast-conn-connected'),

    filter(() => Config.Features.BcastService2 && state$.value.dnBcast.iAmBcaster),

    mergeMap(({ payload }) => {
      const room = state$.value.dnBcast.room

      if (!ServiceBcast.Guards.isRoom(room)) return EMPTY

      const metadata = payload.room.localParticipant.metadata

      if (!metadata) return EMPTY

      try {
        const meta = JSON.parse(metadata)

        console.log(meta)

        if (meta.kind !== ConstBcast.Participant.Bcaster) return EMPTY

        //
      } catch (err) {}

      const { time_limit, time_remaining, id } = state$.value.subscription

      const hasTimeLimit = typeof time_limit === 'number'

      console.log('hasTimeLimit', hasTimeLimit)

      if (!hasTimeLimit) return EMPTY

      const timeRemaining = time_remaining || 0

      return of(CountdownBcastDurationAC.start(id, timeRemaining))
    }),
  )
