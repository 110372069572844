import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Config } from '../../../../config'
import { ModalsIds } from '../../../../constants/modals'
import { useCustomAuth } from '../../../../hooks/auth/use-custom-auth'
import { StoreState } from '../../../../models/app/model'
import { StorePlanFieldsMR } from '../../../../models/store/plan/model'
import { DangerTrans } from '../../../common/intl/danger-trans'
import { genAnimatedDialog } from '../../compound-dialog/dialog/component'
import { DialogConfirmation } from '../component'
import { STDialogsConfirmationThanksForBuyExtra } from './style'

// ~~~~~~ Constants

const Dialog = genAnimatedDialog(ModalsIds.ConfirmThanksForBuy)

// ~~~~~~ Component

export const DialogConfirmThanksForBuy = () => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  const { login } = useCustomAuth()

  // ~~~~~~ State

  const [doClose, setDoClose] = useState(0)

  const modalsConfirm = useSelector((state: StoreState) => state.modals.confirm)

  // ~~~~~~ Computed

  const onConfirm = modalsConfirm[ModalsIds.ConfirmThanksForBuy]

  // ~~~~~~ Handlers

  function onClickConfirm() {
    onConfirm && onConfirm()

    setDoClose(performance.now())

    login()
  }

  function onEndDissapear() {
    dispatch(StorePlanFieldsMR.reset.MC.reset())
  }

  // ~~~~~~ Render

  return (
    <DialogConfirmation
      Dialog={Dialog}
      predefIcon="success"
      title="dialogs.confirm.thanks-for-buy.Title"
      contentText="dialogs.confirm.thanks-for-buy.Content"
      okButtonIntl="common.sign.In"
      okButtonColorType="success"
      extra={
        <STDialogsConfirmationThanksForBuyExtra>
          <DangerTrans
            id="common.NoticeStore"
            values={{
              c: (chunks: string) => (
                <a
                  key="store-link"
                  href={Config.Links.UpgradeBroadcastURL}
                  target="_blank"
                  rel="noreferrer"
                >
                  {chunks}
                </a>
              ),
            }}
          />
        </STDialogsConfirmationThanksForBuyExtra>
      }
      hideCloseButton={true}
      isRunning={false}
      doClose={doClose}
      onEndDisappear={onEndDissapear}
      onClickClose={() => undefined}
      onClickConfirm={onClickConfirm}
    />
  )
}
