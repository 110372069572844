import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Navigate, useParams } from 'react-router'
import { Routes } from '../../constants/routes/routes'
import { SessionStorageDebug } from '../../services/storage/session/debug'
import { DebuggerMC } from '../../store/actions-mutators/debugger/mutators'

export const DebugPage = () => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()
  const { kind } = useParams<{ kind: 'qa' | 'on' | 'off' }>()

  // ~~~~~~ Effects

  useEffect(() => {
    if (kind === 'on' || kind === 'qa') {
      SessionStorageDebug.set(kind)
      dispatch(DebuggerMC.enable(kind))
    } else {
      SessionStorageDebug.clear()
      dispatch(DebuggerMC.disable())
    }
  }, [dispatch, kind])

  return <Navigate to={Routes.Main} />
}
