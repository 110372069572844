import { Observable, filter, map } from 'rxjs'
import { StateObservable, ofType } from 'redux-observable'
import { ServiceBcast } from '@dn/bcast'
import { StoreState } from '../../../../models/app/model'
import { EpicApiBcastRoomSubMC, EpicApiBcastRoomSubMT } from '../../api-bcast/room/sub/mutators'
import { Config } from '../../../../config'

type Action = ReturnType<typeof EpicApiBcastRoomSubMC.ok>

export const chainingFromRoomForSubToStartViewer$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(EpicApiBcastRoomSubMT.OK),

    filter(
      () =>
        (Config.Features.BcastService2 && !Config.Features.BcastCheckVersion) ||
        (Config.Features.BcastService2 &&
          Config.Features.BcastCheckVersion &&
          state$.value.root.version === 'v2'),
    ),

    map(({ payload }) => {
      const wsUrl = `${Config.Bcast.Protocol}${payload.server}`

      return ServiceBcast.React.Actions.AC.viewerStart(wsUrl, payload.token)
    }),
  )
