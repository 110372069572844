import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { LoaderBalls } from '../../../components/common/loader-balls/component'
import { ModalsIds } from '../../../constants/modals'
import { Routes } from '../../../constants/routes/routes'
import { useExtensionGetCurrentUser } from '../../../hooks/extension/use-get-current-user'
import { ExtensionAC } from '../../../store/actions/extension/actions'
import { ModalsMC } from '../../../store/actions-mutators/modals/mutators'
import { ShareMainStreamAC } from '../../../store/actions/share-main-stream/actions'
import { UtilsLog } from '../../../utils/logs'

// ~~~~~~ Component

export const ExtensionStartBcastPage = () => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  const navigate = useNavigate()

  // ~~~~~~ Callback

  const extensionGetCurrentUserSuccess = useCallback(
    (data: DN.Services.Extension.AppInfoPayload | undefined) => {
      if (!data) {
        navigate(Routes.Join, { replace: true })
        return
      }

      UtilsLog.devLog('Extension user data', data)

      dispatch(ExtensionAC.userData(data))

      const { subscription } = data

      if (
        subscription.plan_type === 'free' &&
        !subscription.free_premium_period_end &&
        typeof subscription.time_remaining === 'number' &&
        subscription.time_remaining < 1
      ) {
        dispatch(ModalsMC.open(ModalsIds.FreeAccountWithoutTime))

        navigate(Routes.Start, { replace: true })

        return
      }

      navigate(Routes.Start, { replace: true })

      dispatch(ShareMainStreamAC.getStream())
    },
    [dispatch, navigate],
  )

  const extensionGetCurrentUserError = useCallback(() => {
    navigate(Routes.Main, { replace: true })
  }, [navigate])

  // ~~~~~~ Dependent hooks

  useExtensionGetCurrentUser(extensionGetCurrentUserSuccess, extensionGetCurrentUserError)

  // ~~~~~~ Render

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <LoaderBalls />
    </div>
  )
}
