import { Config } from '../../../config'
import { ConstHttpVerbs } from '@dn/constants'

// ~~~~~~ [P]

const getV1Path = (path: string) => `${Config.Api.Bcast.URI}/broadcast-api/v1${path}`

const getV2Path = (path: string) => `${Config.Api.Bcast.URI}/broadcast-api/v2${path}`

// const getMockedPath = (path: string) => `${Config.Api.Bcast.LOCAL_URI}${path}`

// ~~~~~~ Users

const CurrentUser = {
  Show: {
    Method: ConstHttpVerbs.GET,
    Path: '/current-user/broadcast-web',
    getUrl: () => getV1Path(CurrentUser.Show.Path),
  },
}

// ~~~~~~ User Settings

const UserSettings = {
  Show: {
    Method: ConstHttpVerbs.GET,
    Path: '/users/:userId/settings',
    getUrl: (userId: string) => getV1Path(UserSettings.Show.Path.replace(':userId', userId)),
  },

  Update: {
    Method: ConstHttpVerbs.PATCH,
    Path: '/users/:userId/settings',
    getUrl: (userId: string) => getV1Path(UserSettings.Update.Path.replace(':userId', userId)),
  },

  Delete: {
    Method: ConstHttpVerbs.DELETE,
    Path: '/users/:userId/settings',
    getUrl: (userId: string) => getV1Path(UserSettings.Delete.Path.replace(':userId', userId)),
  },
}

// ~~~~~~ Subscription

const Subscriptions = {
  Show: {
    Method: ConstHttpVerbs.GET,
    Path: '/current-user/broadcast-web/subscription',
    getUrl: () => getV1Path(Subscriptions.Show.Path),
  },

  Time: {
    Method: ConstHttpVerbs.GET,
    Path: '/subscriptions/:subscriptionId/time',
    getUrl: (subscriptionId: string) =>
      getV1Path(Subscriptions.Time.Path).replace(':subscriptionId', subscriptionId),
  },
}

// ~~~~~~ Providers

const Providers = {
  GetGoogle: {
    Method: ConstHttpVerbs.GET,
    Path: '/users/:userId/providers/google',
    getUrl: (userId: string) => getV1Path(Providers.GetGoogle.Path.replace(':userId', userId)),
  },

  LinkGoogle: {
    Method: ConstHttpVerbs.POST,
    Path: '/users/:userId/providers/google',
    getUrl: (userId: string) => getV2Path(Providers.LinkGoogle.Path.replace(':userId', userId)),
  },

  UnlinkGoogle: {
    Method: ConstHttpVerbs.DELETE,
    Path: '/users/:userId/providers/google',
    getUrl: (userId: string) => getV1Path(Providers.UnlinkGoogle.Path).replace(':userId', userId),
  },
}

// ~~~~~~ Google Class Room

const GoogleClassRoom = {
  List: {
    Method: ConstHttpVerbs.GET,
    Path: '/providers/google/courses',
    getUrl: () => getV1Path(GoogleClassRoom.List.Path),
  },

  Announce: {
    Method: ConstHttpVerbs.POST,
    Path: '/providers/google/courses/:courseId/announcements',
    getUrl: (courseId: string) =>
      getV1Path(GoogleClassRoom.Announce.Path).replace(':courseId', courseId),
  },
}

// ~~~~~~ Google Slides

const GoogleSlides = {
  List: {
    Method: ConstHttpVerbs.GET,
    Path: '/providers/google/presentations',
    getUrl: () => getV1Path(GoogleSlides.List.Path),
  },

  CreatePresentation: {
    Method: ConstHttpVerbs.POST,
    Path: '/providers/google/presentations',
    getUrl: () => getV1Path(GoogleSlides.CreatePresentation.Path),
  },

  AddSlide: {
    Method: ConstHttpVerbs.PUT,
    Path: '/providers/google/presentations/:presentationId',
    getUrl: (presentationId: string) =>
      getV1Path(GoogleSlides.AddSlide.Path).replace(':presentationId', presentationId),
  },
}

// ~~~~~~ Stream Context

const StreamContext = {
  Create: {
    Method: ConstHttpVerbs.POST,
    Path: '/stream-session-contexts',
    getUrl: () => getV1Path(StreamContext.Create.Path),
  },

  Get: {
    Method: ConstHttpVerbs.GET,
    Path: '/stream-session-contexts/:bcastId',
    getUrl: (bcastId: string) => getV1Path(StreamContext.Get.Path).replace(':bcastId', bcastId),
  },
}

// ~~~~~~ Room

const Room = {
  ForPub: {
    Method: ConstHttpVerbs.PUT,
    path: '/bcast/room/:bcastId',
    getUrl: (bcastId: string) => getV2Path(Room.ForPub.path).replace(':bcastId', bcastId),
  },

  ForSub: {
    Method: ConstHttpVerbs.GET,
    path: '/bcast/room/:bcastId',
    getUrl: (bcastId: string) => getV2Path(Room.ForSub.path).replace(':bcastId', bcastId),
  },

  Version: {
    Method: ConstHttpVerbs.GET,
    path: '/bcast/room/:bcastId/version',
    getUrl: (bcastId: string) => getV2Path(Room.Version.path).replace(':bcastId', bcastId),
  },
}

// ~~~~~~

export const ApiBcastRoutes = {
  GoogleSlides,
  UserSettings,
  CurrentUser,
  Subscriptions,
  Providers,
  GoogleClassRoom,
  StreamContext,
  BcastRoom: Room,
} as const
