import { Link } from 'react-router'
import { Routes } from '../../../constants/routes/routes'

// DN

export const DNPrivacyMail = () => (
  <a href="mailto:privacy@displaynote.com">privacy@displaynote.com</a>
)

export const DNInfoMail = () => <a href="mailto:info@displaynote.com">info@displaynote.com</a>

export const DNContactPage = () => (
  <a target="_blank" href="https://www.displaynote.com/contact" rel="noreferrer">
    contact page
  </a>
)

export const DNPrivacyPolicy = () => <Link to={Routes.Privacy}>Privacy Policy</Link>

export const DNCookiePolicy = () => <Link to={Routes.Cookies}>Cookie Policy</Link>

export const DNPrivacyAndCookiePolicies = () => (
  <>
    <DNPrivacyPolicy /> <span> </span> and <span> </span> <DNCookiePolicy />
  </>
)

// Mailchimp

export const MailChimpPrivacy = () => (
  <a target="_blank" rel="noreferer noreferrer" href="https://mailchimp.com/legal/privacy/">
    Privacy Policy
  </a>
)

export const MailChimpTerms = () => (
  <a target="_blank" rel="noreferrer" href="https://mailchimp.com/legal/terms/">
    Terms
  </a>
)

export const MailChimpPrivacyAndTerms = () => (
  <>
    <MailChimpPrivacy /> <span> </span> and <span> </span> <MailChimpTerms />
  </>
)

// HubSpot

export const HubSpotPrivacy = () => (
  <a
    target="_blank"
    rel="noreferrer"
    href="https://legal.hubspot.com/privacy-policy?_ga=2.218109531.333476375.1525104299-1263470695.1524760632"
  >
    Privacy Policy
  </a>
)

export const HubSpotTerms = () => (
  <a target="_blank" rel="noreferrer" href="https://legal.hubspot.com/data-privacy">
    Terms
  </a>
)

export const HubSpotPrivacyAndTerms = () => (
  <>
    <HubSpotPrivacy /> <span> </span> and <span> </span> <HubSpotTerms />
  </>
)

// Zoho

export const ZohoPrivacy = () => (
  <a target="_blank" rel="noreferrer" href="https://www.zoho.com/privacy.html">
    Privacy Policy
  </a>
)

export const ZohoTerms = () => (
  <a target="_blank" rel="noreferrer" href="https://www.zoho.com/terms.html">
    Terms
  </a>
)

export const ZohoPrivacyAndTerms = () => (
  <>
    <ZohoPrivacy /> <span> </span> and <span> </span> <ZohoTerms />
  </>
)
