import { Link } from 'react-router'
import { Config } from '../../../config'
import { Routes } from '../../../constants/routes/routes'
import { DangerTrans } from '../intl/danger-trans'
import { STFooter } from './style'

// ~~~~~~ Types

type Props = {
  forceTheme?: Theme
}

// ~~~~~~ Component

export const Footer: React.FC<Props> = ({ forceTheme }) => {
  // ~~~~~~ Render

  return (
    <STFooter $forceTheme={forceTheme}>
      <DangerTrans
        id="common-components.footer.Info"
        values={{
          // Cookies

          c: (chunks: string) => (
            <Link key="cookies" to={Routes.Cookies}>
              {chunks}
            </Link>
          ),

          // Privacy

          d: (chunks: string) => (
            <Link key="privacy" to={Routes.Privacy}>
              {chunks}
            </Link>
          ),

          // Terms

          e: (chunks: string) => (
            <Link key="terms" to={Routes.Terms}>
              {chunks}
            </Link>
          ),

          // Support

          f: (chunks: string) => (
            <a
              key="support-info"
              href={Config.Links.SupportInfo}
              className="support"
              rel="noreferrer"
              target="_blank"
            >
              {chunks}
            </a>
          ),
        }}
      />
    </STFooter>
  )
}
