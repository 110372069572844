import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { Trans } from '../../components/common/intl/trans'
import { LazySVG } from '../../components/svgs/lazy-svg/component'
import { Config } from '../../config'
import { ModalsIds } from '../../constants/modals'
import { BroadcastIdRouteParams, Routes } from '../../constants/routes/routes'
import { useCheckIsBroadcastIdOrRedirect } from '../../hooks/check-params/use-check-param-broadcast-id'
import { useModelRootUpdateUserkind } from '../../hooks/model/root/use-model-root-update-userkind'
import { StoreState } from '../../models/app/model'
import { RootModelFieldsMR } from '../../models/bcast/root/model'
import { UiLayoutPanelsFieldsMR } from '../../models/bcast/ui-layout-panels/model'
import { ModalsMC } from '../../store/actions-mutators/modals/mutators'
import { ApiStreamManagerServerAC } from '../../store/actions/api-stream-manager/server/actions'
import { BroadcastAC } from '../../store/actions/bcast/actions'
import { UtilsLog } from '../../utils/logs'
import { ClassName, STJoiningToBroadcastPage } from './style'
import { BcastTrackEvents } from '../../services/track-events'
import { useTrackStreamContext } from '../../hooks/track-events/use-track-stream-context'
import { ServiceBcast } from '@dn/bcast'
import { ApiBcastRoomAC } from '../../store/actions/api-bcast/room/actions'

// ~~~~~~ Constants

const Logo = LazySVG('logos/broadcast-square')

// ~~~~~~ Vars

// Needed bcause the async nature of setState

let willRedirectToJoined = false

// ~~~~~~ Component

export const JoiningToBroadcastPage = () => {
  // ~~~~~~ Hooks

  useModelRootUpdateUserkind('viewer')

  const dispatch = useDispatch()

  const navigate = useNavigate()

  const { broadcastId: paramBroadcastId } = useParams<BroadcastIdRouteParams>()

  const isValidBroadcastId = useCheckIsBroadcastIdOrRedirect({
    broadcastId: paramBroadcastId,
    redirectIfUndef: false,
    redirectTo: Routes.Main,
  })

  const streamContext = useTrackStreamContext()

  // ~~~~~~ State

  const {
    broadcastId: serverSubBroadcastId,
    errors,
    uiStatus,
  } = useSelector((state: StoreState) => state.streamManagerServerSubMain)

  const dnBcast = useSelector((state: StoreState) => state.dnBcast)

  const {
    lastEvent,
    isReady,
    username,
    id: streamManagerSubMainId,
  } = useSelector((state: StoreState) => state.streamManagerSubMain)

  const { shouldExit, reason: exitReason } = useSelector((state: StoreState) => state.exit)

  const modals = useSelector((state: StoreState) => state.modals.list)

  const { redirectPath } = useSelector((state: StoreState) => state.root)

  const root = useSelector((state: StoreState) => state.root)

  // ~~~~~~ Computed

  const getServerIsRunning = uiStatus === 'running' || root.uiRoomVersion === 'running'

  const finalErrors =
    !!errors.length || !!dnBcast.getSubDataErrors.length || !!root.uiRoomVersionErrors.length

  const finalBcastId = paramBroadcastId || serverSubBroadcastId || dnBcast.bcastId

  // ~~~~~~ Effects

  // - On mount get id if is a link from classroom

  useEffect(() => {
    willRedirectToJoined = false

    BcastTrackEvents.calls.Page.view('/joining')

    const queryParams = new URLSearchParams(window.location.search)

    const isClassRoomLink = !!queryParams.get('gcroom')

    // - Set as Viewer

    dispatch(ServiceBcast.React.Mutators.MC.setIAmBcaster(false))

    if (streamManagerSubMainId !== '------') {
      BcastTrackEvents.calls.JoinKind.input()

      return
    }

    isClassRoomLink
      ? BcastTrackEvents.calls.JoinKind.croomLink()
      : BcastTrackEvents.calls.JoinKind.bcastLink()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // - Set redirect path

  useEffect(() => {
    dispatch(RootModelFieldsMR.redirectPath.MC.change(Routes.Join))
  }, [dispatch])

  // - Bcast Check Kind

  useEffect(() => {
    if (!Config.Features.BcastCheckVersion || root.uiRoomVersion !== 'init' || !finalBcastId) return

    dispatch(ApiBcastRoomAC.version(finalBcastId))

    //
  }, [dispatch, finalBcastId, root.uiRoomVersion])

  // - Redirect to Join if kind is completed and unknown (errors)

  useEffect(() => {
    if (
      !Config.Features.BcastCheckVersion ||
      root.uiRoomVersion !== 'completed' ||
      root.version !== 'unknown'
    ) {
      return
    }

    UtilsLog.devLog(
      String.fromCodePoint(128256),
      '/joining',
      'bcast kind completed and unknown',
      Routes.Join,
    )

    navigate(Routes.Join)

    //
  }, [navigate, root.uiRoomVersion, root.version])

  // - Open a dialog with the exit reason

  useEffect(() => {
    if (!shouldExit) return

    switch (exitReason) {
      case 'user-limit':
        dispatch(ModalsMC.open(ModalsIds.ConfirmUserLimitReached))
        break

      case 'network-offline':
        dispatch(ModalsMC.open(ModalsIds.ConfirmViewerDisconnectedByOffline))
        break

      case 'data-channel-error':
      case 'conn-fail':
        dispatch(ModalsMC.open(ModalsIds.ConfirmViewerDisconnectedByConnFail))
        break

      case 'websoket-fail':
        dispatch(ModalsMC.open(ModalsIds.ConfirmViewerDisconnectedByWebsocketFail))
        break

      case 'invalid-name':
      case 'session-end':
        dispatch(ModalsMC.open(ModalsIds.ViewHasEnded))
        break

      case 'video-too-many-progress':
        dispatch(ModalsMC.open(ModalsIds.ConfirmViewerDisconnectedByVideoLoad))
        break

      case 'user-manual-exit':
      default:
        dispatch(ModalsMC.open(ModalsIds.ConfirmViewerDisconnectedByConnFail))
    }

    //
  }, [dispatch, shouldExit, exitReason])

  // - Redirect to Main if no id (red5pro)

  useEffect(() => {
    if (Config.Features.BcastService2 && !Config.Features.BcastCheckVersion) return

    if (
      Config.Features.BcastService2 &&
      Config.Features.BcastCheckVersion &&
      root.version !== 'v1'
    ) {
      return
    }

    if (serverSubBroadcastId || paramBroadcastId) return

    UtilsLog.devLog(String.fromCodePoint(128256), '/joining', 'no id redirect to', redirectPath)

    navigate(redirectPath, { replace: true })

    //
  }, [paramBroadcastId, navigate, serverSubBroadcastId, redirectPath, root.version])

  // - Redirect to Main if no id (liveKit)

  useEffect(() => {
    if (!Config.Features.BcastService2) return

    if (Config.Features.BcastCheckVersion && root.version !== 'v2') {
      return
    }

    if (dnBcast.bcastId || paramBroadcastId) return

    UtilsLog.devLog(String.fromCodePoint(128256), '/joining', 'no id redirect to', redirectPath)

    navigate(redirectPath, { replace: true })

    //
  }, [paramBroadcastId, navigate, redirectPath, dnBcast.bcastId, root.version])

  // - Redirect to Main if server has errors (red5pro)

  useEffect(() => {
    if (Config.Features.BcastService2 && !Config.Features.BcastCheckVersion) return

    if (
      Config.Features.BcastService2 &&
      Config.Features.BcastCheckVersion &&
      root.version !== 'v1'
    ) {
      return
    }

    if (!finalErrors) return

    UtilsLog.devLog(
      String.fromCodePoint(128256),
      '/joining',
      'server errors redirect to',
      redirectPath,
      finalErrors,
    )

    navigate(redirectPath, { replace: true })

    //
  }, [finalErrors, navigate, redirectPath, root.version])

  // - Redirect to Main if server has errors (livekit)

  useEffect(() => {
    if (!Config.Features.BcastService2) return

    if (Config.Features.BcastCheckVersion && root.version !== 'v2') {
      return
    }

    if (!finalErrors) return

    UtilsLog.devLog(
      String.fromCodePoint(128256),
      '/joining',
      'server errors redirect to',
      redirectPath,
      finalErrors,
    )

    navigate(redirectPath, { replace: true })

    //
  }, [finalErrors, navigate, redirectPath, root.version])

  // - Get sub server if we have only liveId (red5pro)

  useEffect(() => {
    if (Config.Features.BcastService2 && !Config.Features.BcastCheckVersion) return

    if (
      Config.Features.BcastService2 &&
      Config.Features.BcastCheckVersion &&
      root.version !== 'v1'
    ) {
      return
    }

    if (
      modals.length ||
      serverSubBroadcastId ||
      !paramBroadcastId ||
      (paramBroadcastId && !isValidBroadcastId) ||
      getServerIsRunning
    ) {
      return
    }

    dispatch(ApiStreamManagerServerAC.getServerSubMain(paramBroadcastId))

    //
  }, [
    dispatch,
    getServerIsRunning,
    paramBroadcastId,
    modals.length,
    serverSubBroadcastId,
    isValidBroadcastId,
    root.version,
  ])

  // - Get sub server if we have only liveId (livekit)

  useEffect(() => {
    if (!Config.Features.BcastService2) return

    if (Config.Features.BcastCheckVersion && root.version !== 'v2') {
      return
    }

    if (
      modals.length ||
      dnBcast.bcastId ||
      !paramBroadcastId ||
      (paramBroadcastId && !isValidBroadcastId) ||
      dnBcast.getSubDataUiStatus === 'running' ||
      exitReason !== 'init'
    ) {
      return
    }

    dispatch(ApiBcastRoomAC.forSub(paramBroadcastId))

    //
  }, [
    dispatch,
    dnBcast.bcastId,
    dnBcast.getSubDataUiStatus,
    exitReason,
    isValidBroadcastId,
    modals.length,
    paramBroadcastId,
    root.version,
  ])

  // - Redirect to Joined if all connection stuff is done
  // and connect the Communication Channel (red5pro)

  useEffect(() => {
    if (Config.Features.BcastService2 && !Config.Features.BcastCheckVersion) return

    if (
      Config.Features.BcastService2 &&
      Config.Features.BcastCheckVersion &&
      root.version !== 'v1'
    ) {
      return
    }

    if (willRedirectToJoined || !serverSubBroadcastId || !isReady) return

    willRedirectToJoined = true

    // Before navigate, set left panel as closed because the viewer
    // don't have main left panel, only share paint left panel
    // left panel is reset to its initial state "open" on cancel-all action

    dispatch(UiLayoutPanelsFieldsMR.savedPaintingLeftPanelOpenState.MC.change('closed'))

    dispatch(UiLayoutPanelsFieldsMR.savedNotPaintingLeftPanelOpenState.MC.change('closed'))

    dispatch(UiLayoutPanelsFieldsMR.leftPanelOpenState.MC.change('closed'))

    // Navigate

    UtilsLog.devLog(String.fromCodePoint(128256), '/joining', 'all ok redirect to', '/joined')

    navigate(Routes.genJoined(serverSubBroadcastId), { replace: true })

    dispatch(
      BroadcastAC.commChannelInit({
        env: Config.Api.Bcast.WS.ENV,
        meetId: serverSubBroadcastId,
        kind: 'viewer',
        username,
      }),
    )

    // Track

    BcastTrackEvents.calls.Streams.SubMain.stream(streamContext)

    //
  }, [navigate, serverSubBroadcastId, dispatch, username, isReady, streamContext, root.version])

  // - Redirect to Joined if all connection stuff is done (liveKit)

  useEffect(() => {
    if (!Config.Features.BcastService2) return

    if (Config.Features.BcastCheckVersion && root.version !== 'v2') return

    if (
      willRedirectToJoined ||
      !dnBcast.publicBcastId ||
      !dnBcast.roomIsConnected ||
      !dnBcast.remoteScreenStream
    ) {
      return
    }

    willRedirectToJoined = true

    // Before navigate, set left panel as closed because the viewer
    // don't have main left panel, only share paint left panel
    // left panel is reset to its initial state "open" on cancel-all action

    dispatch(UiLayoutPanelsFieldsMR.savedPaintingLeftPanelOpenState.MC.change('closed'))

    dispatch(UiLayoutPanelsFieldsMR.savedNotPaintingLeftPanelOpenState.MC.change('closed'))

    dispatch(UiLayoutPanelsFieldsMR.leftPanelOpenState.MC.change('closed'))

    // Navigate

    UtilsLog.devLog(String.fromCodePoint(128256), '/joining', 'all ok redirect to', '/joined')

    navigate(Routes.genJoined(dnBcast.publicBcastId), { replace: true })

    // Track

    BcastTrackEvents.calls.Streams.SubMain.stream(streamContext)

    //
  }, [
    dispatch,
    dnBcast.publicBcastId,
    dnBcast.remoteScreenStream,
    dnBcast.roomIsConnected,
    navigate,
    root.version,
    streamContext,
  ])

  // ~~~~~~ Render

  return (
    <STJoiningToBroadcastPage>
      <div className={`${ClassName}--center`}>
        {/* Logo */}

        <div className={`${ClassName}--center--logo`}>
          <Logo size={64} />
        </div>

        {/* Joining a broadcast... */}

        <div className={`${ClassName}--center--joining`}>
          <Trans id="pages.joining.Title" />
        </div>

        {/* Join events */}

        <div className={`${ClassName}--center--events`}>
          <Trans id={`events.sub.${lastEvent}`} />
        </div>
      </div>

      {/* - */}
    </STJoiningToBroadcastPage>
  )
}
