import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import { Config } from '../../../config'
import { ModalsIds } from '../../../constants/modals'
import { Routes } from '../../../constants/routes/routes'
import { useCustomAuth } from '../../../hooks/auth/use-custom-auth'
import { StoreState } from '../../../models/app/model'
import { ModalsMC } from '../../../store/actions-mutators/modals/mutators'
import { RootMC } from '../../../store/actions-mutators/root/mutators'
import { UtilsLog } from '../../../utils/logs'
import { ExtensionService } from '../../../services/extension'
import { ApiBcastUserSettingsAC } from '../../../store/actions/api-bcast/user-settings/actions'
import { UtilsStore } from '@dn/utils'

// ~~~~~~ Hook

export const useMenuLogic = () => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  const { logout, isLoading } = useCustomAuth()

  const navigate = useNavigate()

  const location = useLocation()

  // ~~~~~~ State

  const [logoutIsRunning, setLogoutIsRunning] = useState(false)

  const {
    id: currentUserId,
    email: currentUserEmail,
    uiStatus: currentUserUiStatus,
  } = useSelector((state: StoreState) => state.currentUser)

  const streamMangerPub = useSelector((state: StoreState) => state.streamManagerPubMain)

  const showSideMenu = useSelector((state: StoreState) => state.root.showSideMenu)

  const userSettings = useSelector((state: StoreState) => state.userSettings)

  const userSettingsUiStatus = useSelector((state: StoreState) => state.userSettingsEdit.uiStatus)

  // ~~~~~~ Computed

  const showMenuLoader =
    (!Config.AllBlocked && isLoading) || currentUserUiStatus === 'running' || logoutIsRunning

  const signOutWithoutModal = streamMangerPub.uiStatus !== 'running' && !streamMangerPub.publisher

  const showOnboarding = userSettings.onboarding_is_completed

  const resetOnboardingIsRunning = userSettingsUiStatus === 'running'

  // ~~~~~~ Handlers

  function doLogout() {
    setLogoutIsRunning(true)

    UtilsLog.devLog(String.fromCodePoint(128256), 'useMenuOnSelection', 'navigate to main')

    ExtensionService.SendMessage.ToExtension.loggedStateChanged('not-logged')

    const returnTo =
      location.pathname === Routes.Start || location.pathname === Routes.Join
        ? window.location.href
        : Config.LogoutUrl

    !Config.AllBlocked &&
      logout({
        logoutParams: {
          returnTo,
        },
      })

    dispatch(UtilsStore.Mutators.MC.reset())
  }

  function onSelection(
    itemSelected:
      | 'manage-account'
      | 'support'
      | 'settings'
      | 'sign-out-now'
      | 'sign-out-stop-bcast',
  ) {
    onHideSideMenu()

    switch (itemSelected) {
      case 'manage-account':
        window.open(Config.Links.ManageAccount)

        break

      case 'settings':
        navigate(Routes.UserSettings, { replace: true })

        break

      case 'sign-out-now':
        // Added to next tick to prevent aria-menubutton throw an exception

        setTimeout(() => {
          doLogout()
        }, 0)

        break

      case 'sign-out-stop-bcast':
        dispatch(ModalsMC.open(ModalsIds.ConfirmStopBroadcasting, doLogout))

        break

      case 'support':
        window.open(Config.Links.Feedback)
        break

      default:
        return
    }
  }

  function onHideSideMenu() {
    dispatch(RootMC.hideSideMenu())
  }

  function opClickOnboarding() {
    if (resetOnboardingIsRunning || !userSettings.onboarding_is_completed) return

    dispatch(
      ApiBcastUserSettingsAC.update(currentUserId, {
        onboarding_create_a_bcast: false,
        onboarding_change_source: false,
        onboarding_share: false,
        onboarding_live_draw: false,
        onboarding_is_completed: false,
      }),
    )
  }

  // ~~~~~~

  return {
    currentUserEmail,
    showMenuLoader,

    signOutWithoutModal,

    onSelection,

    showOnboarding,
    resetOnboardingIsRunning,
    opClickOnboarding,

    showSideMenu,
    onHideSideMenu,

    locationIsSettings: location.pathname === Routes.UserSettings,
  } as const
}
