import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { fromEvent, debounceTime, filter } from 'rxjs'
import Scrollbars from 'react-custom-scrollbars'
import { animate } from 'framer-motion'
import { ConstEventsElement, ConstEventsWindow, ConstKeyCodes } from '@dn/constants'
import { withFadeMove } from '../../../../hocs/fade-move'
import { withStackedModal } from '../../../../hocs/stacked-modal'
import { ModalsMC } from '../../../../store/actions-mutators/modals/mutators'
import { DialogsMargins } from '../../../../style/constants/dialogs'
import { Trans } from '../../../common/intl/trans'
import { LazySVG } from '../../../svgs/lazy-svg/component'
import { STDialog } from './style'
import { DialogContext } from '../context/dialog-context'
import { UtilsMobile } from '@dn/utils'
import { Colors } from '../../../../style/theme/colors'

// ~~~~~~ Constants

const IconClose = LazySVG('icons/times')
const IconBack = LazySVG('icons/arrow-back')

const IconInfo = LazySVG('icons/info')
const IconSuccess = LazySVG('icons/circle-success')
const IconError = LazySVG('icons/circle-error')

const extraInnerHeight = 0 // 16 no scroll but more size that wanted

// ~~~~~~ Types

export type DialogProps = {
  stackedBtns: boolean
  showCloseInStackedDialogWithoutTitle?: true
  showBackInStackedDialogWithoutTitle?: true
  onClickBack?: () => void

  icon?: {
    Icon: ReturnType<typeof LazySVG>
    color: ThemeColor
  }

  predefIcon?: 'info' | 'success' | 'error'
  predefIconColor?: ThemeColor

  borderColor?: ThemeColor

  dialogTitle: IntlMsgId
  dialogTitleValues?: { [key: string]: number | string }
  dialogTitleAlign?: 'left' | 'center' | 'right'
  removeContentTitleMarginBottom?: true

  dialogSubtitle?: IntlMsgId
  dialogSubtitleValues?: { [key: string]: number | string }

  hideCloseBtn?: true
  showESC?: true
  onWillBeClosed?: () => void

  children: React.ReactNode

  needScroll?: { top: number }
  onScroll?: () => void

  closeOnClickOut?: boolean
  closeDialog?: number

  onClickCloseModal?: () => void

  disableHorizontalScroll?: true

  // From fade-move
  modalId?: string
  isActiveModal?: boolean
  iHaveBeenClickedOut?: number
  iHaveDisappeared?: number
  onContentHeightChanged?: (height: number) => void
  startDissapear?: () => void
  endDisappear?: () => void
}

// ~~~~~~ Component

export const Dialog: React.FC<DialogProps> = ({
  stackedBtns,
  showCloseInStackedDialogWithoutTitle,
  showBackInStackedDialogWithoutTitle,
  onClickBack,
  onClickCloseModal,
  disableHorizontalScroll,

  icon,
  predefIcon,
  predefIconColor,

  dialogTitle,
  dialogTitleValues,
  dialogTitleAlign,
  removeContentTitleMarginBottom,

  dialogSubtitle,
  dialogSubtitleValues,

  hideCloseBtn,
  showESC,

  closeOnClickOut,

  needScroll,
  onScroll,

  closeDialog,
  onWillBeClosed,

  // From React
  children,

  // From fade-move
  modalId,
  isActiveModal,
  iHaveBeenClickedOut,
  iHaveDisappeared,
  onContentHeightChanged,
  startDissapear,
  endDisappear: endDissapear,
}) => {
  // ~~~~~ Hooks

  const dispatch = useDispatch()

  // ~~~~~ State

  const [closeDialogInit] = useState(closeDialog)

  const [fadeMoveClickedOut] = useState(iHaveBeenClickedOut)

  const [dissapearStarted, setDissapearStarted] = useState(false)

  const [scrollBarHeight, setScrollBarHeight] = useState<number | undefined>()

  const [maxScrollBarHeight, setMaxScrollBarHeight] = useState<number | undefined>()

  const [insideScrollHeight, setInsideScrollHeight] = useState(0)

  const selfRef = useRef<HTMLDivElement>(null)

  const scrollbarRef = useRef<Scrollbars>(null)
  const insideScrollRef = useRef<HTMLDivElement>(null)
  const outsideScrollRef = useRef<HTMLDivElement>(null)

  const headerRef = useRef<HTMLDivElement>(null)
  const actionsRef = useRef<HTMLDivElement>(null)

  // ~~~~~ Computed

  const iconColor =
    predefIconColor ||
    (predefIcon === 'info'
      ? Colors.BRONX_30_40
      : predefIcon === 'error'
        ? Colors.BROOKLYN_40_30
        : predefIcon === 'success'
          ? Colors.SITO_40_30
          : undefined)

  // ~~~~~ Callbacks

  const doStartDissapear = useCallback(() => {
    setDissapearStarted(true)
    onWillBeClosed && onWillBeClosed()
    startDissapear && startDissapear()
  }, [onWillBeClosed, startDissapear])

  const onClickCloseModalH = useCallback(() => {
    if (!modalId || !isActiveModal) return

    onClickCloseModal && onClickCloseModal()

    if (!startDissapear) {
      onWillBeClosed && onWillBeClosed()
      dispatch(ModalsMC.close(modalId))
      return
    }

    doStartDissapear()

    //
  }, [
    dispatch,
    doStartDissapear,
    isActiveModal,
    modalId,
    onClickCloseModal,
    onWillBeClosed,
    startDissapear,
  ])

  // ~~~~~~ Effects

  // - Start dissapear from clicked out

  useEffect(() => {
    if (closeOnClickOut === false) return

    if (!startDissapear || dissapearStarted) return

    if (fadeMoveClickedOut !== iHaveBeenClickedOut) {
      doStartDissapear()
    }
  }, [
    closeOnClickOut,
    dissapearStarted,
    doStartDissapear,
    fadeMoveClickedOut,
    iHaveBeenClickedOut,
    onWillBeClosed,
    startDissapear,
  ])

  // - Start dissapear from from component declaring this dialog

  useEffect(() => {
    if (
      closeDialog === undefined ||
      closeDialog === closeDialogInit ||
      !startDissapear ||
      dissapearStarted
    ) {
      return
    }

    const activeElement = document.activeElement as any
    activeElement && typeof activeElement.blur === 'function' && activeElement.blur()

    doStartDissapear()
  }, [
    closeDialog,
    closeDialogInit,
    dissapearStarted,
    doStartDissapear,
    onWillBeClosed,
    startDissapear,
  ])

  // - Dissapear ended

  useEffect(() => {
    if (!modalId || !iHaveDisappeared) return

    dispatch(ModalsMC.close(modalId))

    endDissapear && endDissapear()

    //
  }, [dispatch, iHaveDisappeared, modalId, endDissapear])

  // - ESC pressed

  useEffect(() => {
    if (!showESC) return

    const sub = fromEvent<KeyboardEvent>(document, ConstEventsElement.KeyDown)
      .pipe(filter((evt) => evt.code === ConstKeyCodes.ESC))
      .subscribe({
        next: () => {
          onClickCloseModalH()
        },
      })

    return () => {
      sub.unsubscribe()
    }
  }, [onClickCloseModalH, showESC])

  // - Recalculate height: is necessary to recalculate height after every render.

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!selfRef.current) return

    const titleHeight =
      headerRef.current?.clientHeight || 0 + (outsideScrollRef.current?.clientHeight || 0)

    const contentHeight =
      (((insideScrollRef.current?.childNodes[0] as any) || null)?.clientHeight || 0) +
      extraInnerHeight

    const actionsHeight = actionsRef.current?.clientHeight || 0

    const maxHeight =
      window.innerHeight - titleHeight - actionsHeight - DialogsMargins.WindowAndDialogs

    setMaxScrollBarHeight(maxHeight)

    const finalContentHeight =
      contentHeight && contentHeight > maxHeight ? maxHeight : contentHeight

    const height = titleHeight + actionsHeight + finalContentHeight

    if (height && onContentHeightChanged) onContentHeightChanged(height - extraInnerHeight)

    setScrollBarHeight(finalContentHeight)

    setInsideScrollHeight(contentHeight)
  })

  // - Fix the content size

  useEffect(() => {
    const interval = setInterval(() => {
      const contentHeight =
        (((insideScrollRef.current?.childNodes[0] as any) || null)?.clientHeight || 0) +
        extraInnerHeight

      if (contentHeight === insideScrollHeight) return

      setInsideScrollHeight(contentHeight)
    }, 50)

    return () => {
      clearInterval(interval)
    }
  }, [insideScrollHeight])

  // - Window Resize: it triggers a change in the state to repaint the component

  useEffect(() => {
    const sub = fromEvent(window, ConstEventsWindow.Resize)
      .pipe(debounceTime(100))
      .subscribe({
        next: () => setScrollBarHeight(0),
      })

    return () => {
      sub.unsubscribe()
    }
  }, [])

  // - Move the scroll

  useEffect(() => {
    if (needScroll === undefined || !scrollbarRef.current) return

    const fromCurrentScrollPos = scrollbarRef.current.getScrollTop()
    const toTop = needScroll.top

    const controls = animate(fromCurrentScrollPos, toTop, {
      type: 'tween',
      onUpdate: (topPosition) => {
        if (!scrollbarRef.current) return

        scrollbarRef.current.scrollTop(topPosition)
      },
    })

    return controls.stop
  }, [needScroll])

  // ~~~~~ Render

  return (
    <STDialog
      data-testid={`dialog-${modalId}`}
      ref={selfRef}
      $stackedBtns={stackedBtns}
      $iconColor={iconColor || icon?.color}
      $titleAlign={dialogTitleAlign}
      $removeContentTitleMarginBottom={removeContentTitleMarginBottom}
      $disableHorizontalScroll={disableHorizontalScroll}
    >
      {/* Title + Title buttons: Not for stacked */}

      {stackedBtns ? undefined : (
        <div ref={headerRef} className="dialog-header">
          {/* Title */}

          <div className="title">
            <div className="text">
              <div data-testid="title">
                <Trans id={dialogTitle} values={dialogTitleValues} />
              </div>

              {dialogSubtitle ? (
                <div data-testid="subtitle" className="subtext">
                  <Trans id={dialogSubtitle} values={dialogSubtitleValues} />
                </div>
              ) : undefined}
            </div>

            {/* Buttons */}

            <div className="btns-holder">
              {/* Close Button */}

              {!hideCloseBtn ? (
                <div className="btn-close" onClick={onClickCloseModalH}>
                  {/* X */}

                  <IconClose size={13} />

                  {/* ESC */}

                  {showESC && !UtilsMobile.calcIsMobile() ? (
                    <div className="esc">ESC</div>
                  ) : undefined}
                </div>
              ) : undefined}
            </div>
          </div>
        </div>
      )}

      {/* Content and Actions */}

      <div className="dialog-content-actions">
        {/* Content */}

        <DialogContext.Provider value={{ kind: 'content' }}>
          {children ? (
            <div className="dialog-content">
              {stackedBtns ? (
                <div
                  ref={outsideScrollRef}
                  className={`dialog-content-title ${
                    showCloseInStackedDialogWithoutTitle ? 'with-stacked-btn-close' : ''
                  }`}
                >
                  {/* Subtitle: Like step x of y */}

                  {dialogSubtitle ? (
                    <div data-testid="subtitle" className="subtext">
                      <Trans id={dialogSubtitle} values={dialogSubtitleValues} />
                    </div>
                  ) : undefined}

                  {/* Icon */}

                  {icon ? (
                    <div className="dialog-icon">
                      <icon.Icon size={40} />
                    </div>
                  ) : undefined}

                  {predefIcon ? (
                    <div className="dialog-icon">
                      {predefIcon === 'info' ? (
                        <IconInfo size={40} />
                      ) : predefIcon === 'error' ? (
                        <IconError size={40} />
                      ) : predefIcon === 'success' ? (
                        <IconSuccess size={40} />
                      ) : undefined}
                    </div>
                  ) : undefined}

                  {/* Title */}

                  <div data-testid="title">
                    <Trans id={dialogTitle} values={dialogTitleValues} />
                  </div>

                  {/* <- */}

                  {showBackInStackedDialogWithoutTitle ? (
                    <div className="dialog-stacked-btn-back" onClick={onClickBack}>
                      <IconBack size={16} />
                    </div>
                  ) : undefined}

                  {/* X */}

                  {showCloseInStackedDialogWithoutTitle ? (
                    <div className="dialog-stacked-btn-close" onClick={onClickCloseModalH}>
                      <IconClose size={16} />
                    </div>
                  ) : undefined}
                </div>
              ) : undefined}

              <Scrollbars
                data-testid="dialog-scroller"
                className="dialog-scroller"
                ref={scrollbarRef}
                universal
                autoHide={true}
                autoHeight={true}
                autoHeightMin={scrollBarHeight || 0}
                autoHeightMax={maxScrollBarHeight}
                onScroll={onScroll}
                renderTrackVertical={(props) => <div {...props} className="scrollbar-vertical" />}
                renderTrackHorizontal={(props) => (
                  <div {...props} style={{ display: 'none' }} className="scrollbar-horizontal" />
                )}
              >
                <div className="inside-scroll" style={{ overflow: 'hidden' }} ref={insideScrollRef}>
                  {children}
                </div>
              </Scrollbars>
            </div>
          ) : undefined}
        </DialogContext.Provider>

        {/* Actions */}
        <div ref={actionsRef}>
          <DialogContext.Provider value={{ kind: 'actions' }}>{children}</DialogContext.Provider>
        </div>
      </div>
    </STDialog>
  )
}

// ~~~~~~ Generator

export const genAnimatedDialog = (modalId: string) =>
  withStackedModal(withFadeMove(Dialog), modalId)
