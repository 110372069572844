import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { ModalsIds } from '../../../../constants/modals'
import { StoreState } from '../../../../models/app/model'
import { genAnimatedDialog } from '../../compound-dialog/dialog/component'
import { DialogConfirmation } from '../component'

// ~~~~~~ Constants

const Dialog = genAnimatedDialog(ModalsIds.ConfirmBroadcasterDisconectedByFail)

// ~~~~~~ Component

export const DialogConfirmBroadcasterDisconnectedByFail = () => {
  // ~~~~~~ Hooks

  const navigate = useNavigate()

  // ~~~~~~ State

  const [doClose, setDoClose] = useState(0)

  const { redirectPath } = useSelector((state: StoreState) => state.root)

  // ~~~~~~ Handlers

  function onClickOk() {
    setDoClose(performance.now())
  }

  function onEndDissapear() {
    navigate(redirectPath, { replace: true })
  }

  // ~~~~~~ Render

  return (
    <DialogConfirmation
      Dialog={Dialog}
      title="dialogs.confirm.broadcaster-disconnected-by-fail.Title"
      contentText="dialogs.confirm.broadcaster-disconnected-by-fail.Content"
      okButtonIntl="common.OK"
      isRunning={false}
      doClose={doClose}
      onClickConfirm={onClickOk}
      hideCloseButton={true}
      onClickClose={() => undefined}
      onEndDisappear={onEndDissapear}
    />
  )
}
