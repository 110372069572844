import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { UtilsFullscreen } from '@dn/utils'
import { ServiceBcast } from '@dn/bcast'
import { Footer } from '../../components/common/footer/component'
import { LayoutCup } from '../../components/layouts/cup/component'
import { LayoutCupContent } from '../../components/layouts/cup/parts/content/component'
import { LayoutCupFooter } from '../../components/layouts/cup/parts/footer/component'
import { LayoutCupTopRight } from '../../components/layouts/cup/parts/top/right/component'
import { Routes } from '../../constants/routes/routes'
import { useModelRootUpdateUserkind } from '../../hooks/model/root/use-model-root-update-userkind'
import { StoreState } from '../../models/app/model'
import { RootModelFieldsMR } from '../../models/bcast/root/model'
import { ExitMC } from '../../store/actions-mutators/exit/mutators'
import { UtilsLog } from '../../utils/logs'
import { JoinContent } from './content/component'
import { STJoinPage } from './style'
import { JoinPageTopRight } from './top/right/component'
import { BcastTrackEvents } from '../../services/track-events'
import { Config } from '../../config'

// ~~~~~~ Component

export const JoinPage = () => {
  // ~~~~~~ Hooks

  useModelRootUpdateUserkind('viewer')

  const dispatch = useDispatch()

  const navigate = useNavigate()

  // ~~~~~~ State

  // - Store

  const { broadcastId: broadcastIdSub, errors: streamManagerServerSubMainErrors } = useSelector(
    (state: StoreState) => state.streamManagerServerSubMain,
  )

  const dnBcast = useSelector((state: StoreState) => state.dnBcast)

  const exit = useSelector((state: StoreState) => state.exit)

  const root = useSelector((state: StoreState) => state.root)

  // ~~~~~~ Effects

  // - Page event

  useEffect(() => {
    BcastTrackEvents.calls.Page.view('/join')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // - On mount: Exit fullscreen
  //             Exit PIP
  //             Set new redirect path
  //             Set as Viewer

  useEffect(() => {
    UtilsFullscreen.exitFullScreen()

    try {
      document.exitPictureInPicture().catch(() => undefined)
    } catch (err) {}

    dispatch(RootModelFieldsMR.redirectPath.MC.change(Routes.Join))

    // - Set as Viewer

    dispatch(ServiceBcast.React.Mutators.MC.setIAmBcaster(false))

    //
  }, [dispatch])

  // - Redirect to joining (red5pro)

  useEffect(() => {
    if (Config.Features.BcastService2 && !Config.Features.BcastCheckVersion) return

    if (
      Config.Features.BcastService2 &&
      Config.Features.BcastCheckVersion &&
      root.version !== 'v1'
    ) {
      return
    }

    if (!broadcastIdSub || exit.shouldExit) return

    UtilsLog.devLog(String.fromCodePoint(128256), '/join', 'redirect to', '/joining', 'v1')

    navigate(Routes.genJoining(broadcastIdSub), { replace: true })

    //
  }, [exit.shouldExit, navigate, broadcastIdSub, root.version])

  // - Redirect to joining (livekit)

  useEffect(() => {
    if (!Config.Features.BcastService2) return

    if (Config.Features.BcastCheckVersion && root.version !== 'v2') {
      return
    }

    if (!dnBcast.publicBcastId || exit.shouldExit) return

    UtilsLog.devLog(String.fromCodePoint(128256), '/join', 'redirect to', '/joining', 'v2')

    navigate(Routes.genJoining(dnBcast.publicBcastId), { replace: true })

    //
  }, [exit.shouldExit, navigate, dnBcast.publicBcastId, root.version])

  // - Redirect to joining (bcast-version)

  useEffect(() => {
    if (
      !Config.Features.BcastService2 ||
      !Config.Features.BcastCheckVersion ||
      !root.bcastId ||
      exit.shouldExit ||
      root.version === 'unknown' ||
      root.uiRoomVersionErrors.length ||
      streamManagerServerSubMainErrors.length
    ) {
      return
    }

    UtilsLog.devLog(
      String.fromCodePoint(128256),
      '/join',
      'redirect to',
      '/joining',
      'kind:',
      root.version,
    )

    navigate(Routes.genJoining(root.bcastId), { replace: true })

    //
  }, [
    root.bcastId,
    exit.shouldExit,
    navigate,
    root.version,
    root.uiRoomVersionErrors.length,
    streamManagerServerSubMainErrors,
  ])

  // - Reset exit... (red5pro)

  useEffect(() => {
    if (Config.Features.BcastService2 && !Config.Features.BcastCheckVersion) return

    if (
      Config.Features.BcastService2 &&
      Config.Features.BcastCheckVersion &&
      root.version !== 'v1'
    ) {
      return
    }

    if (!broadcastIdSub && exit.shouldExit) {
      dispatch(ExitMC.resetExit())
    }

    //
  }, [dispatch, exit.shouldExit, broadcastIdSub, root.version])

  // - Reset exit... (livekit)

  useEffect(() => {
    if (!Config.Features.BcastService2) return

    if (Config.Features.BcastCheckVersion && root.version !== 'v2') {
      return
    }

    if (!dnBcast.bcastId && exit.shouldExit) {
      dispatch(ExitMC.resetExit())
    }

    //
  }, [dispatch, exit.shouldExit, dnBcast.bcastId, root.version])

  // ~~~~~~ Render

  return (
    <STJoinPage data-testid="join-page">
      <LayoutCup>
        {/* Top */}

        {/* - Right */}

        <LayoutCupTopRight>
          <JoinPageTopRight />
        </LayoutCupTopRight>

        {/* Content */}

        <LayoutCupContent>
          <JoinContent />
        </LayoutCupContent>

        {/* Footer */}

        <LayoutCupFooter>
          <Footer />
        </LayoutCupFooter>

        {/* - */}
      </LayoutCup>
    </STJoinPage>
  )
}
