import { AnimatePresence, motion } from 'framer-motion'
import { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { fromEvent } from 'rxjs'
import { ConstEventsWindow } from '@dn/constants'
import { Button } from '../../components/common/button/component'
import { FeedbackEndBcast } from '../../components/common/feedback-end-bcast/component'
import { Footer } from '../../components/common/footer/component'
import { InfoBoxDeviceNotSupported } from '../../components/common/infos/box/device-not-supported/component'
import { Trans } from '../../components/common/intl/trans'
import { MenuExtensionSourceSelector } from '../../components/common/menus/extension-source-selector/component'
import { LayoutCup } from '../../components/layouts/cup/component'
import { LayoutCupContent } from '../../components/layouts/cup/parts/content/component'
import { LayoutCupFooter } from '../../components/layouts/cup/parts/footer/component'
import { LayoutCupTopRight } from '../../components/layouts/cup/parts/top/right/component'
import BroadcastBrand from '../../components/svgs/logos/broadcast-brand/component'
import { Config } from '../../config'
import { ModalsIds } from '../../constants/modals'
import { Routes } from '../../constants/routes/routes'
import { useCustomAuth } from '../../hooks/auth/use-custom-auth'
import { useModelRootUpdateUserkind } from '../../hooks/model/root/use-model-root-update-userkind'
import { StoreState } from '../../models/app/model'
import { RootModelFieldsMR } from '../../models/bcast/root/model'
import { ExtensionService } from '../../services/extension'
import { ExitMC } from '../../store/actions-mutators/exit/mutators'
import { ModalsMC } from '../../store/actions-mutators/modals/mutators'
import { ShareMainStreamAC } from '../../store/actions/share-main-stream/actions'
import { UtilsFullscreen } from '@dn/utils'
import { UtilsLog } from '../../utils/logs'
import { clearSavedPaintCanvas } from '../3-started/content/main/live-paint/component'
import { ClassName, STStartContent, STStartPage } from './style'
import { StartPageTopRight } from './top/right/component'
import { BcastTrackEvents } from '../../services/track-events'
import { ServiceBcast } from '@dn/bcast'

// ~~~~~~ Component

export const StartPage = () => {
  // ~~~~~~ Hooks

  useModelRootUpdateUserkind('bcaster')

  const { isLoading: authIsLoading } = useCustomAuth()

  const navigate = useNavigate()

  const dispatch = useDispatch()

  // ~~~~~~ State

  // - Local

  const [showExtensionSourceSelector, setShowExtensionSourceSelector] = useState(false)

  const [, setUpdate] = useState(0)

  // - Store

  const {
    id: currentUserId,
    email: currentUserEmail,
    uiStatus: currentUserUiStatus,
    isSessionChecked,
  } = useSelector((state: StoreState) => state.currentUser)

  const streamManagerPub = useSelector((state: StoreState) => state.streamManagerPubMain)

  const streamManagerServerSub = useSelector(
    (state: StoreState) => state.streamManagerServerSubMain,
  )

  const connectionStatus = useSelector((state: StoreState) => state.root.connectionStatus)

  const getSreenUIStatus = useSelector(
    (state: StoreState) => state.sharedMainStream.getSreenUiStatus,
  )

  const subscription = useSelector((state: StoreState) => state.subscription)

  const broadcastIdPub = useSelector(
    (state: StoreState) => state.streamManagerServerPubMain.broadcastId,
  )

  const exit = useSelector((state: StoreState) => state.exit)

  const dnBcast = useSelector((state: StoreState) => state.dnBcast)

  // - Refs

  const startBCastRef = useRef<HTMLDivElement>(null)

  const extensionSourceSelectorRef = useRef<HTMLDivElement>(null)

  // ~~~~~~ Computed

  const userIsLogged = !!currentUserEmail

  const userIsLoading = (!Config.AllBlocked && authIsLoading) || currentUserUiStatus === 'running' // || logoutIsRunning

  const userTimeLimit =
    userIsLogged && typeof subscription.time_remaining === 'number'
      ? subscription.time_remaining
      : -1

  const userDaysLeft =
    userIsLogged && typeof subscription.daysLeft === 'number' ? subscription.daysLeft : 0

  const getScreenIsRunning = getSreenUIStatus === 'running'

  const getServerIsRunning = streamManagerServerSub.uiStatus === 'running'

  const publishIsRunning = streamManagerPub.uiStatus === 'running'

  const timeRemainig = subscription.time_remaining

  const startABcastIsDisabledByTime = typeof timeRemainig === 'number' && timeRemainig <= 0

  const startIsRunning = getScreenIsRunning || publishIsRunning

  const startIsDisabled =
    getServerIsRunning ||
    getScreenIsRunning ||
    userIsLoading ||
    publishIsRunning ||
    connectionStatus === 'offline'

  // ~~~~~~ Handlers

  function onClickLearnMore() {
    window.open(Config.Links.ExtensionInfo)
  }

  function onClickStartABcast(evt?: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    evt && evt.stopPropagation()

    if (startABcastIsDisabledByTime) {
      dispatch(ModalsMC.open(ModalsIds.CreateAnAccountFreeOrBuy))

      return
    }

    if (!userIsLogged) {
      dispatch(RootModelFieldsMR.prevPath.MC.change(Routes.Start))

      navigate(Routes.SignIn, { replace: true })

      return
    }

    if (showExtensionSourceSelector) {
      setShowExtensionSourceSelector(false)
      return
    }

    ExtensionService.SendMessage.ToExtension.checkExtension$().subscribe({
      next: () => {
        // Extension: Show extension menu

        setShowExtensionSourceSelector(true)
      },
      error: () => {
        // No extension: Get stream

        dispatch(ShareMainStreamAC.getStream())
      },
    })
  }

  function onClickSourceSelector(kind: CaptureStreamKind) {
    setShowExtensionSourceSelector(false)

    switch (kind) {
      case 'app':
        // ...
        break

      case 'tab':
        // ...
        break

      case 'desktop':
        // ...
        break
    }

    ExtensionService.SendMessage.ToExtension.checkExtension$().subscribe({
      next: () => {
        dispatch(ShareMainStreamAC.getStreamByExtension(kind))
      },
      error: () => {
        dispatch(ShareMainStreamAC.getStream())
      },
    })
  }

  // ~~~~~~ Effects

  // - Page event

  useEffect(() => {
    BcastTrackEvents.calls.Page.view('/start')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // - On mount: Set new redirect path
  //             Exit fullscreen
  //             Exit PIP
  //             Clear saved paint canvas
  //             Set as Bcaster

  useEffect(() => {
    dispatch(RootModelFieldsMR.redirectPath.MC.change(Routes.Start))

    UtilsFullscreen.exitFullScreen()

    try {
      document.exitPictureInPicture().catch(() => undefined)
    } catch (err) {}

    clearSavedPaintCanvas('start')

    dispatch(ServiceBcast.React.Mutators.MC.setIAmBcaster(true))

    //
  }, [dispatch])

  // - On resize hide the extension source selector

  useEffect(() => {
    const sub = fromEvent(window, ConstEventsWindow.Resize).subscribe({
      next: () => {
        if (!extensionSourceSelectorRef.current) return

        setShowExtensionSourceSelector(false)
      },
    })

    return () => {
      sub.unsubscribe()
    }
  }, [])

  // - Redirect to starting a Broadcast (red5pro)

  useEffect(() => {
    if (Config.Features.BcastService2) return

    if (!broadcastIdPub || exit.shouldExit) return

    UtilsLog.devLog(String.fromCodePoint(128256), '/start', 'redirect to', '/starting')

    navigate(Routes.Starting, { replace: true })

    //
  }, [exit.shouldExit, navigate, broadcastIdPub])

  // - Redirect to starting a Broadcast (LiveKit)

  useEffect(() => {
    if (!Config.Features.BcastService2) return

    if (!dnBcast.bcastId) return

    UtilsLog.devLog(String.fromCodePoint(128256), '/start', 'redirect to', '/starting')

    navigate(Routes.Starting, { replace: true })

    //
  }, [dnBcast.bcastId, navigate])

  // - Reset exit...

  useEffect(() => {
    if (!broadcastIdPub && exit.shouldExit) {
      dispatch(ExitMC.resetExit())
    }

    //
  }, [dispatch, exit.shouldExit, broadcastIdPub])

  // - Redirect user to sign-in if not logged/logging

  useEffect(() => {
    if (!isSessionChecked || userIsLoading) return

    if (currentUserId) return

    navigate(Routes.SignIn, { replace: true })

    //
  }, [currentUserId, isSessionChecked, navigate, userIsLoading])

  // ~~~~~~ Render

  return (
    <STStartPage data-testid="start-page">
      {/* Control show feedback on bcast end */}

      {Config.Features.FeedbackBcastEnd ? <FeedbackEndBcast /> : undefined}

      {/* Layout */}

      <LayoutCup>
        {/* Top */}

        {/* - Right */}

        <LayoutCupTopRight>
          <StartPageTopRight
            userIsLogged={userIsLogged}
            userTimeLimit={userTimeLimit}
            userDaysLeft={userDaysLeft}
          />
        </LayoutCupTopRight>

        {/* Content */}

        <LayoutCupContent>
          <STStartContent>
            <div className={`${ClassName}--start-content`}>
              {/* Animated Extension source selector */}

              <AnimatePresence>
                {startBCastRef.current && showExtensionSourceSelector ? (
                  <motion.div
                    initial={{ opacity: 0, y: 16 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0 }}
                    style={{
                      position: 'absolute',
                      zIndex: 1,

                      top:
                        startBCastRef.current?.offsetTop -
                        (extensionSourceSelectorRef.current
                          ? extensionSourceSelectorRef.current.clientHeight + 8
                          : 0),

                      left:
                        startBCastRef.current.offsetLeft +
                        startBCastRef.current.clientWidth / 2 -
                        (extensionSourceSelectorRef.current
                          ? extensionSourceSelectorRef.current.clientWidth / 2
                          : 0),
                    }}
                    onAnimationStart={() => setUpdate(performance.now())}
                  >
                    <MenuExtensionSourceSelector
                      ref={extensionSourceSelectorRef}
                      kind="Source"
                      onClick={onClickSourceSelector}
                      onClickOut={() => setShowExtensionSourceSelector(false)}
                    />
                  </motion.div>
                ) : undefined}
              </AnimatePresence>

              {/* Broadcast */}

              <div className={`${ClassName}--start-content--start-bcast`}>
                <BroadcastBrand />
              </div>

              {/* Info share */}

              <div className={`${ClassName}--start-content--start-info`}>
                <Trans id="pages.start.InfoShare" />
              </div>

              {/* Device not supported */}

              <InfoBoxDeviceNotSupported
                className={`${ClassName}--start-content--device-not-supported`}
              />

              {/* Start Button */}

              <Button
                ref={startBCastRef}
                className={`${ClassName}--start-content--start-action`}
                $width="100%"
                $colorType="primary"
                intlId={userIsLoading ? 'pages.start.actions.Loading' : 'pages.start.actions.Start'}
                $active={!userIsLoading}
                $running={startIsRunning}
                disabled={startIsDisabled}
                onClick={onClickStartABcast}
              />

              {/* Bottom Info: Learn more */}

              <div className={`${ClassName}--start-content--start-bottom-info`}>
                <div>
                  <Trans id="pages.start.extension-info.Info" />
                </div>

                <div
                  data-testid="go-to-learn-more-act"
                  className={`${ClassName}--start-content--start-bottom-info--link`}
                  onClick={onClickLearnMore}
                >
                  <Trans id="pages.start.extension-info.Link" />
                </div>
              </div>
            </div>
          </STStartContent>
        </LayoutCupContent>

        {/* Footer */}

        <LayoutCupFooter>
          <Footer />
        </LayoutCupFooter>

        {/*  */}
      </LayoutCup>
    </STStartPage>
  )
}
