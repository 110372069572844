import { FormattedMessage } from 'react-intl'

// ~~~~~~ Types

type Props = {
  id: IntlMsgId
  values?: {
    [key: string]: string | number
  }
}

// ~~~~~~ Component

export const Trans: React.FC<Props> = ({ id, values }) => {
  if (!id) return '----'

  return <FormattedMessage id={id} defaultMessage={id} values={values} />
}
