import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { UserSettings } from '../../../components/common/user-settings/component'
import { DialogGoogleSignIn } from '../../../components/dialogs/google/sign-in/component'
import { ModalsIds } from '../../../constants/modals'
import { Routes } from '../../../constants/routes/routes'
import { useExtensionGetCurrentUser } from '../../../hooks/extension/use-get-current-user'
import { StoreState } from '../../../models/app/model'
import { RootModelFieldsMR } from '../../../models/bcast/root/model'
import { UiSettingsEditFieldsMR } from '../../../models/bcast/ui-settings/model'
import { ExtensionAC } from '../../../store/actions/extension/actions'
import { STExtensionUserSettingsPage } from './style'

// ~~~~~~ Component

export const ExtensionUserSettingsPage = () => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  const navigate = useNavigate()

  // - Callback for get current user hook

  const extensionGetCurrentUserSuccess = useCallback(
    (data: DN.Services.Extension.AppInfoPayload | undefined) => {
      if (!data) {
        window.close()
        return
      }

      dispatch(ExtensionAC.userData(data))

      dispatch(RootModelFieldsMR.redirectPath.MC.change(Routes.Start))
    },
    [dispatch],
  )

  // - Callback for get current user hook

  const extensionGetCurrentUserError = useCallback(() => {
    navigate(Routes.Main, { replace: true })
  }, [navigate])

  useExtensionGetCurrentUser(extensionGetCurrentUserSuccess, extensionGetCurrentUserError)

  // ~~~~~~ State

  const [isMount, setIsMount] = useState(false)

  const currentUser = useSelector((state: StoreState) => state.currentUser)

  const modals = useSelector((state: StoreState) => state.modals.list)

  // ~~~~~~ Computed

  const modalGoogleSignIn = modals.includes(ModalsIds.GoogleSignIn)

  // ~~~~~~ Effects

  // - On mount select providers section

  useEffect(() => {
    if (isMount) return

    setIsMount(true)

    dispatch(UiSettingsEditFieldsMR.section.MC.change('providers'))

    //
  }, [dispatch, isMount])

  // ~~~~~~ Render

  if (!currentUser.email) return null

  return (
    <>
      {/* Dialog: Google sign-in */}

      {modalGoogleSignIn ? <DialogGoogleSignIn /> : undefined}

      <STExtensionUserSettingsPage>
        <div className="settings-content">
          <UserSettings />
        </div>
      </STExtensionUserSettingsPage>
    </>
  )
}
