import { useNavigate } from 'react-router'
import { Button } from '../../../../components/common/button/component'
import { Routes } from '../../../../constants/routes/routes'
import { STSignInPageTopRight } from './style'

// ~~~~~~ Component

export const SignInPageTopRight = () => {
  // ~~~~~~ Hooks

  const navigate = useNavigate()

  // ~~~~~~ Handlers

  function onClickGoToJoin() {
    navigate(Routes.Join, { replace: true })
  }

  // ~~~~~~ Render

  return (
    <STSignInPageTopRight>
      {/* View a bcast */}

      <div className="sign-in-top-right-to-join">
        <Button
          data-testid="go-to-join-desktop-act"
          className="sign-in-top-right-to-join-desktop"
          $colorType="tertiary-ghost"
          intlId="pages.start.top.ViewBcast"
          $padding="8px 16px"
          onClick={onClickGoToJoin}
          disabled={false}
          $running={false}
          $active={true}
        />

        <Button
          data-testid="go-to-join-mobile-act"
          $size="s"
          className="sign-in-top-right-to-join-mobile"
          $colorType="tertiary-ghost"
          intlId="pages.start.top.ViewBcast"
          $padding="8px 16px"
          onClick={onClickGoToJoin}
          disabled={false}
          $running={false}
          $active={true}
        />
      </div>
    </STSignInPageTopRight>
  )
}
