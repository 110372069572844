import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { Button } from '../../../../components/common/button/component'
import { Trans } from '../../../../components/common/intl/trans'
import { UserMenu } from '../../../../components/common/user-menu/component'
import { ModalsIds } from '../../../../constants/modals'
import { Routes } from '../../../../constants/routes/routes'
import { ModalsMC } from '../../../../store/actions-mutators/modals/mutators'
import { STStartPageTopRight } from './style'
import { BcastTrackEvents } from '../../../../services/track-events'
import { useTrackUserContext } from '../../../../hooks/track-events/use-track-user-context'

// ~~~~~~ Types

type Props = {
  userIsLogged: boolean
  userTimeLimit: number
  userDaysLeft: number
}

// ~~~~~~ Component

export const StartPageTopRight: React.FC<Props> = ({
  userIsLogged,
  userTimeLimit,
  userDaysLeft,
}) => {
  // ~~~~~~ Hooks

  const navigate = useNavigate()

  const dispatch = useDispatch()

  const userContext = useTrackUserContext()

  // ~~~~~~ Handlers

  function onClickUpgradePlan() {
    dispatch(ModalsMC.open(ModalsIds.CreateAnAccountFreeOrBuy))

    // Track

    BcastTrackEvents.calls.BuyProcess.Upgrade['1-select-plan-upgrade'](userContext)
  }

  function onClickGoToJoin() {
    navigate(Routes.Join, { replace: true })
  }

  // ~~~~~~ Render

  return (
    <STStartPageTopRight>
      {/* Free version info */}

      <div className="start-top-right-free-info">
        {/* Free time left */}

        {userTimeLimit > -1 ? (
          <>
            <span className="free-time-limit-desktop">
              <Trans id="pages.start.top.free.TimeLeft" values={{ value: userTimeLimit }} />
            </span>

            <span className="free-time-limit-mobile">{`${userTimeLimit}:00`}</span>
          </>
        ) : undefined}

        {/* Free-Pro days left */}

        {userDaysLeft > 0 ? (
          <>
            <span className="free-premium-days-desktop">
              <Trans id="pages.start.top.free.days-left.Desktop" values={{ value: userDaysLeft }} />
            </span>

            <span className="free-premium-days-mobile">
              <Trans id="pages.start.top.free.days-left.Mobile" values={{ value: userDaysLeft }} />
            </span>
          </>
        ) : undefined}
      </div>

      {/* Upgrade */}

      {userTimeLimit > -1 || userDaysLeft > 0 ? (
        <div className="start-top-right-upgrade" onClick={onClickUpgradePlan}>
          <Trans id="pages.start.top.free.Upgrade" />
        </div>
      ) : undefined}

      {/* View a bcast */}

      <div className="start-top-right-to-join">
        <Button
          data-testid="go-to-join-desktop-act"
          className="start-top-right-to-join-desktop"
          $colorType="tertiary-ghost"
          intlId="pages.start.top.ViewBcast"
          $padding="8px 16px"
          onClick={onClickGoToJoin}
          disabled={false}
          $running={false}
          $active={true}
        />

        <Button
          data-testid="go-to-join-mobile-act"
          className="start-top-right-to-join-mobile"
          $colorType="tertiary-ghost"
          intlId="pages.start.top.ViewBcast"
          $size="s"
          $padding="8px 16px"
          onClick={onClickGoToJoin}
          disabled={false}
          $running={false}
          $active={true}
        />
      </div>

      {/* Menu */}

      {userIsLogged ? (
        <div className="start-top-right-menu">
          <UserMenu />
        </div>
      ) : undefined}
    </STStartPageTopRight>
  )
}
