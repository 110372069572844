import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { Button } from '../../../../components/common/button/component'
import { UserMenu } from '../../../../components/common/user-menu/component'
import { Routes } from '../../../../constants/routes/routes'
import { StoreState } from '../../../../models/app/model'
import { RootModelFieldsMR } from '../../../../models/bcast/root/model'
import { ShareMainStreamAC } from '../../../../store/actions/share-main-stream/actions'
import { STJoinPagetopRight } from './style'

// ~~~~~~ Component

export const JoinPageTopRight = () => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  const navigate = useNavigate()

  // ~~~~~~ State

  const { email: currentUserEmail } = useSelector((state: StoreState) => state.currentUser)

  // ~~~~~~ Computed

  const userIsLogged = !!currentUserEmail

  // ~~~~~~ Handlers

  function onClickCreateBcast() {
    if (userIsLogged) {
      dispatch(ShareMainStreamAC.getStream())

      navigate(Routes.Start, { replace: true })

      return
    }

    dispatch(RootModelFieldsMR.prevPath.MC.change(Routes.Join))

    navigate(Routes.SignIn, { replace: true })
  }

  // ~~~~~~ Render

  return (
    <STJoinPagetopRight>
      <Button
        data-testid="go-to-start-desktop-act"
        className="join-top-right-to-start-desktop"
        $colorType="tertiary-ghost"
        intlId="pages.join.CreateBcast"
        $padding="8px 16px"
        onClick={onClickCreateBcast}
        disabled={false}
        $running={false}
        $active={true}
      />

      <Button
        data-testid="go-to-start-mobile-act"
        $size="s"
        className="join-top-right-to-start-mobile"
        $colorType="tertiary-ghost"
        intlId="pages.join.CreateBcast"
        $padding="8px 16px"
        onClick={onClickCreateBcast}
        disabled={false}
        $running={false}
        $active={true}
      />

      {userIsLogged ? (
        <div className="join-top-right-menu">
          <UserMenu />
        </div>
      ) : undefined}
    </STJoinPagetopRight>
  )
}
