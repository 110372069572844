import React, { useRef } from 'react'
import { StartedLeftPanelMainBcastOnlineInfo } from './bcast-online-info/component'
import { StartedLeftPanelMainClassroom } from './classroom/component'

// ~~~~~~ Types

type Props = {
  panelRef: React.RefObject<HTMLDivElement | null>
  panelMargin: number
}

// ~~~~~~ Components

export const StartedLeftPanelMain: React.FC<Props> = ({ panelRef, panelMargin }) => {
  // ~~~~~~ State

  // - Refs

  const infoRef = useRef<HTMLDivElement>(null)

  // ~~~~~~ Render

  return (
    <>
      {/* Info */}

      <StartedLeftPanelMainBcastOnlineInfo ref={infoRef} />

      {/* Integrations */}

      <StartedLeftPanelMainClassroom
        panelMargin={panelMargin}
        panelElementRef={panelRef}
        topElementRef={infoRef}
      />

      {/* - */}
    </>
  )
}
