import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { Button } from '../../../../components/common/button/component'
import { LazySVG } from '../../../../components/svgs/lazy-svg/component'
import { StoreState } from '../../../../models/app/model'
import { ImageToShareFieldsMR } from '../../../../models/bcast/image-to-share/model'
import { UiLayoutPanelsFieldsMR } from '../../../../models/bcast/ui-layout-panels/model'
import { Layout } from '../../../../style/theme/layout'
import { UtilsImage } from '@dn/utils'
import { StartedLeftPanelSharePaintClassroom } from './classroom/component'
import { StartedLeftPanelSharePaintSlides } from './slides/component'
import { ClassName, STStartedLeftPanelSharePaint } from './style'

// ~~~~~~ Constants

const DownloadIcon = LazySVG('icons/download')

const RefreshIcon = LazySVG('icons/refresh')

// ~~~~~~ Types

type Props = {
  panelRef: React.RefObject<HTMLDivElement | null>
  panelMargin: number
}

// ~~~~~~ Component

export const StartedLeftPanelSharePaint: React.FC<Props> = ({ panelRef, panelMargin }) => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  // ~~~~~~ State

  // - Local

  const [, setUpdate] = useState(0)

  // - Store

  const uiLayoutPanels = useSelector((state: StoreState) => state.uiLayoutPanels)

  const imageToShare = useSelector((state: StoreState) => state.imageToShare)

  // - Refs

  const previewRef = useRef<HTMLDivElement>(null)

  const imageContainerRef = useRef<HTMLDivElement>(null)

  const imageRef = useRef<HTMLImageElement>(null)

  const classroomRef = useRef<HTMLDivElement>(null)

  const slidesRef = useRef<HTMLDivElement>(null)

  const exitRef = useRef<HTMLDivElement>(null)

  // ~~~~~~ Computed

  const diffBetweenImageContainerAndTheImage =
    (imageContainerRef.current?.clientHeight || 0) - (imageRef.current?.clientHeight || 0)

  // ~~~~~~ Handlers

  function onClickCancel() {
    dispatch(
      UiLayoutPanelsFieldsMR.leftPanelOpenState.MC.change(
        uiLayoutPanels.savedPaintingLeftPanelOpenState,
      ),
    )

    dispatch(UiLayoutPanelsFieldsMR.leftPanelShowSharePaints.MC.change(false))

    dispatch(ImageToShareFieldsMR.__model__.MC.completeClear())
  }

  function onClickDownload() {
    UtilsImage.download({
      file: imageToShare.file,
      name: imageToShare.name,
    })
  }

  function onClickRefresh() {
    // Request update image

    dispatch(ImageToShareFieldsMR.__model__.MC.setModel({ reqUpdate: true }))
  }

  // ~~~~~~ Effects

  // - Update on mount to calc diffBetweenImageContainerAndTheImage

  useEffect(() => {
    setUpdate(performance.now())
  }, [])

  // ~~~~~~ Render

  return (
    <STStartedLeftPanelSharePaint>
      <div className={`${ClassName}--content`}>
        {/* Image preview */}

        <div ref={previewRef} className={`${ClassName}--content--screenshot`}>
          <div ref={imageContainerRef} className={`${ClassName}--content--image`}>
            {/* Image */}

            <img ref={imageRef} alt={imageToShare.name} src={imageToShare.file} />

            {/* Actions */}

            <div
              className={`${ClassName}--content--image--actions`}
              style={{
                bottom: Layout.Spacing[2] + diffBetweenImageContainerAndTheImage,
              }}
            >
              {/* Refresh */}

              <div
                className={`${ClassName}--content--image--actions--refresh`}
                onClick={onClickRefresh}
              >
                <RefreshIcon size={20} />
              </div>

              {/* Download */}

              <div
                className={`${ClassName}--content--image--actions--download`}
                onClick={onClickDownload}
              >
                <DownloadIcon size={20} />
              </div>
            </div>

            {/* -- */}
          </div>
        </div>

        {/* Google slides */}

        <div ref={slidesRef} className={`${ClassName}--content--slides-card`}>
          <StartedLeftPanelSharePaintSlides
            panelElementRef={panelRef}
            panelMargin={panelMargin}
            topElementRef={previewRef}
            classroomElementRef={classroomRef}
            exitElementRef={exitRef}
          />
        </div>

        {/* Google classroom */}

        <div ref={classroomRef} className={`${ClassName}--content--classroom-card`}>
          <StartedLeftPanelSharePaintClassroom
            panelElementRef={panelRef}
            panelMargin={panelMargin}
            topElementRef={previewRef}
            slidesElementRef={slidesRef}
            exitElementRef={exitRef}
          />
        </div>
      </div>

      {/* Close */}

      <div ref={exitRef} className={`${ClassName}--exit`}>
        <Button
          $colorType="tertiary-ghost"
          $width="100%"
          intlId="common.Close"
          $active={true}
          $running={false}
          disabled={false}
          onClick={onClickCancel}
        />
      </div>
    </STStartedLeftPanelSharePaint>
  )
}
