import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { Trans } from '../../components/common/intl/trans'
import { LazySVG } from '../../components/svgs/lazy-svg/component'
import { Routes } from '../../constants/routes/routes'
import { useCheckAuth } from '../../hooks/auth/use-check-auth'
import { StoreState } from '../../models/app/model'
import { STRootPage } from './style'
import { BcastTrackEvents } from '../../services/track-events'

// ~~~~~~ Constants

const Logo = LazySVG('logos/broadcast-square')

// ~~~~~~ Component

export const RootPage = () => {
  // ~~~~~~ Hooks

  const navigate = useNavigate()

  useCheckAuth()

  // ~~~~~~ State

  const { id, isSessionChecked, uiStatus } = useSelector((state: StoreState) => state.currentUser)

  // ~~~~~~ Effects

  // - Page event

  useEffect(() => {
    BcastTrackEvents.calls.Page.view('/')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!isSessionChecked || uiStatus === 'running') return

    id ? navigate(Routes.Start, { replace: true }) : navigate(Routes.Join, { replace: true })

    //
  }, [isSessionChecked, id, navigate, uiStatus])

  // ~~~~~~ Render

  return (
    <STRootPage>
      <div className="root-content">
        <div className="root-logo">
          <Logo size={64} />
        </div>

        <div className="root-info">
          <Trans id="pages.root.Info" />
          ...
        </div>
      </div>
    </STRootPage>
  )
}
