import { useSelector, useDispatch } from 'react-redux'
import { StoreState } from '../../../../models/app/model'
import { ClassName, STMotionOnboardingButton } from './style'
import { Trans } from '../../intl/trans'
import { useLocation } from 'react-router'
import { Routes } from '../../../../constants/routes/routes'
import { UiOnboardingFieldsMR } from '../../../../models/bcast/ui-onboarding/model'
import { AnimatePresence } from 'framer-motion'
import { UserSettingsEditFieldsMR } from '../../../../models/bcast/user-settings/model'
import { useEffect } from 'react'
import { ApiBcastUserSettingsAC } from '../../../../store/actions/api-bcast/user-settings/actions'

// ~~~~~~ Constants

const ValidPaths = [Routes.Start, Routes.Starting, Routes.Started]

// ~~~~~~ Component

export const OnboardingButton = () => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  const path = useLocation().pathname

  // ~~~~~~ State

  const uiOnboarding = useSelector((state: StoreState) => state.uiOnboarding)

  const currentUserId = useSelector((state: StoreState) => state.currentUser.id)

  const userSettings = useSelector((state: StoreState) => state.userSettings)

  // ~~~~~~ Computed

  const isValidPath = ValidPaths.includes(path as any)

  const isStartPath = path === Routes.Start

  const shouldBeVisible =
    !userSettings.onboarding_is_completed &&
    (isStartPath || (isValidPath && userSettings.isOnboardingRunning))

  const onboardingId100 = userSettings.onboardingPercent === 100

  // ~~~~~~ Handlers

  function onClick() {
    // Onboarding start when user clicks the button,
    // and user cannot disable it

    isStartPath &&
      !onboardingId100 &&
      dispatch(UserSettingsEditFieldsMR.isOnboardingRunning.MC.change(true))

    // Show

    dispatch(UiOnboardingFieldsMR.panelState.MC.change('show'))
  }

  // ~~~~~~ Effects

  // - Set not running if is 100% and we are in start route
  //
  // Permit to run the next effect

  useEffect(() => {
    if (!currentUserId || !isStartPath || !onboardingId100 || !userSettings.isOnboardingRunning) {
      return
    }

    // Ensure hidden

    dispatch(UiOnboardingFieldsMR.panelState.MC.change('hidden'))

    dispatch(UiOnboardingFieldsMR.buttonState.MC.change('hidden'))

    // Set not running

    dispatch(UserSettingsEditFieldsMR.isOnboardingRunning.MC.change(false))

    //
  }, [currentUserId, dispatch, isStartPath, onboardingId100, userSettings.isOnboardingRunning])

  // - Set as completed if is 100% and we are in start route
  //
  // The completed status is set when the user click the close
  // button of the panel or the button after 100% complete
  // when the "Hurra" is shown

  useEffect(() => {
    if (
      !currentUserId ||
      !isStartPath ||
      !onboardingId100 ||
      userSettings.onboarding_is_completed ||
      userSettings.isOnboardingRunning ||
      uiOnboarding.buttonState !== 'hidden'
    ) {
      return
    }

    dispatch(
      ApiBcastUserSettingsAC.update(currentUserId, {
        onboarding_is_completed: true,
      }),
    )

    //
  }, [
    currentUserId,
    dispatch,
    isStartPath,
    onboardingId100,
    uiOnboarding.buttonState,
    userSettings.isOnboardingRunning,
    userSettings.onboarding_is_completed,
  ])

  // - On...

  useEffect(() => {
    if (!currentUserId) return

    if (
      uiOnboarding.panelState === 'hidden' &&
      !onboardingId100 &&
      userSettings.onboarding_create_a_bcast
    ) {
      onClick()
    }

    // Ignoring because depends on first mount only
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // ~~~~~~ Render

  return (
    <AnimatePresence>
      {shouldBeVisible && uiOnboarding.buttonState !== 'hidden' ? (
        <STMotionOnboardingButton
          className={uiOnboarding.panelState === 'show' ? 'no-pointer' : ''}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          //
          onClick={onClick}
        >
          {/* (B) */}

          <div className={`${ClassName}--button`}>B</div>

          {/* Getting Started */}

          <div className={`${ClassName}--text`}>
            <Trans id="common-components.onboarding.Start" />
          </div>

          {/* -- */}
        </STMotionOnboardingButton>
      ) : undefined}
    </AnimatePresence>
  )
}
