import { Link } from 'react-router'
import { Cookies } from '../../../components/common/cookies/component'
import { DNPrivacyPolicy } from '../../../components/common/privacy-terms-links/component'
import {
  TableDefinition,
  TablesDefinitioins,
} from '../../../components/common/tables/definitions/component'

import { LayoutText } from '../../../components/layouts/text/component'
import { Routes } from '../../../constants/routes/routes'
import { STCookies } from './style'

// ~~~~~~ Constants

const Definitions: TableDefinition[] = [
  {
    word: 'Cookie',
    def: 'means a small file placed on your computer or device by Our Site when you visit certain parts of Our Site and/or when you use certain features of Our Site;',
  },

  {
    word: 'Cookie Law',
    def: 'means the relevant parts of the Privacy and Electronic Communications (EC Directive) Regulations 2003 and of EU Regulation 2016/679 General Data Protection Regulation (“GDPR”);',
  },

  {
    word: 'Personal data',
    def: 'means any and all data that relates to an identifiable person who can be directly or indirectly identified from that data, as defined by EU Regulation 2016/679 General Data Protection Regulation (“GDPR”); and',
  },

  {
    word: 'We/Us/Our',
    def: 'Means DisplayNote Technologies Limited, a limited company registered in Northern Ireland under company number NI1610261, whose registered address is Concourse 3 Building, Queens Road, Belfast, BT3 9DT.',
  },
]

// ~~~~~~ Component

export const CookiesPage: React.FC<{}> = () => {
  // ~~~~~~ Render

  return (
    <LayoutText>
      <STCookies>
        <h1>Cookies</h1>

        <h2>BACKGROUND</h2>

        <p>
          This website <Link to={Routes.Main}>www.broadcast.com</Link> (“Our Site”) uses Cookies and
          similar technologies in order to distinguish you from other users. By using Cookies, We
          are able to provide you with a better experience and to improve Our Site by better
          understanding how you use it. Please read this Cookie Policy carefully and ensure that you
          understand it. Your acceptance of Our Cookie Policy is deemed to occur when you press the
          accept button on our cookie pop-up. If you do not agree to Our Cookie Policy, please stop
          using Our Site immediately.
        </p>

        <h2>1. Definitions and Interpretation</h2>

        <p>
          1.1 In this Cookie Policy, unless the context otherwise requires, the following
          expressions have the following meanings:
        </p>

        <TablesDefinitioins definitions={Definitions} />

        <h2>2. Information About Us</h2>

        <p>
          2.1 Our Site is owned and operated by DisplayNote Technologies Limited, a limited company
          registered in Northern Ireland under company number NI1610261, whose registered address is
          Concourse 3 Building, Queens Road, Belfast, BT3 9DT.
        </p>

        <p>2.2 Our VAT number is GB125654319.</p>

        <h2>3. How Does Our Site Use Cookies?</h2>

        <p>
          3.1 Our Site may place and access certain first party Cookies on your computer or device.
          First party Cookies are those placed directly by Us and are used only by Us. We use
          Cookies to facilitate and improve your experience of Our Site and to provide and improve
          Our products and services. We have carefully chosen these Cookies and have taken steps to
          ensure that your privacy and personal data is protected and respected at all times.
        </p>

        <p>
          3.2 By using Our Site, you may also receive certain third-party Cookies on your computer
          or device. Third party Cookies are those placed by websites, services, and/or parties
          other than Us. Third party Cookies are used on Our Site for analytics. For more details,
          please refer to section 4 below.
        </p>

        <p>
          3.3 All Cookies used by and on Our Site are used in accordance with current Cookie Law. We
          may use some or all of the following types of Cookie:
        </p>

        <p>
          3.3.1 Strictly Necessary Cookies A Cookie falls into this category if it is essential to
          the operation of Our Site, supporting functions such as logging in, your shopping basket,
          and payment transactions.
        </p>

        <p>
          3.3.2 Analytics Cookies It is important for Us to understand how you use Our Site, for
          example, how efficiently you are able to navigate around it, and what features you use.
          Analytics Cookies enable us to gather this information, helping Us to improve Our Site and
          your experience of it.
        </p>

        <p>
          3.3.3 Functionality Cookies Functionality Cookies enable Us to provide additional
          functions to you on Our Site such as personalisation and remembering your saved
          preferences. Some functionality Cookies may also be strictly necessary Cookies, but not
          all necessarily fall into that category.
        </p>

        <p>
          3.3.4 Targeting Cookies It is important for Us to know when and how often you visit Our
          Site, and which parts of it you have used (including which pages you have visited and
          which links you have visited). As with analytics Cookies, this information helps us to
          better understand you and, in turn, to make Our Site and advertising more relevant to your
          interests. Some information gathered by targeting Cookies may also be shared with third
          parties.
        </p>

        <p>
          3.3.5 Third Party Cookies Third party Cookies are not placed by Us; instead, they are
          placed by third parties that provide services to Us and/or to you. Third party Cookies may
          be used by advertising services to serve up tailored advertising to you on Our Site, or by
          third parties providing analytics services to Us (these Cookies will work in the same way
          as analytics Cookies described above).
        </p>

        <p>
          3.3.6 Persistent Cookies Any of the above types of Cookie may be a persistent Cookie.
          Persistent Cookies are those which remain on your computer or device for a predetermined
          period and are activated each time you visit Our Site.
        </p>

        <p>
          3.3.7 Session Cookies Any of the above types of Cookie may be a session Cookie. Session
          Cookies are temporary and only remain on your computer or device from the point at which
          you visit Our Site until you close your browser. Session Cookies are deleted when you
          close your browser.
        </p>

        <p>3.4 Cookies on Our Site are not permanent and will expire within 1 year.</p>

        <p>
          3.5 For more details of the personal data that We collect and use, the measures we have in
          place to protect personal data, your legal rights, and our legal obligations, please refer
          to our
          <span> </span>
          <DNPrivacyPolicy />.
        </p>

        <p>
          3.6 For more specific details of the Cookies that We use, please refer to the table below.
        </p>

        <h2>4. What Cookies Does Our Site Use?</h2>

        <Cookies sectionNumber={4} />

        <h2>5. Consent and Control</h2>

        <p>
          5.1 Before Cookies are placed on your computer or device, you will be shown a pop-up
          requesting your consent to set those Cookies. By giving your consent to the placing of
          Cookies you are enabling Us to provide the best possible experience and service to you.
          You may, if you wish, deny consent to the placing of Cookies unless those Cookies are
          strictly necessary; however certain features of Our Site may not function fully or as
          intended.
        </p>

        <p>
          5.2 In addition to the controls that We provide, you can choose to enable or disable
          Cookies in your internet browser. Most internet browsers also enable you to choose whether
          you wish to disable all Cookies or only third-party Cookies. By default, most internet
          browsers accept Cookies but this can be changed. For further details, please consult the
          help menu in your internet browser or the documentation that came with your device.
        </p>

        <p>
          5.3 The links below provide instructions on how to control Cookies in all mainstream
          browsers:
        </p>

        <p>
          5.3.1 Google Chrome:
          <span> </span>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://support.google.com/chrome/answer/95647?hl=en-GB"
          >
            https://support.google.com/chrome/answer/95647?hl=en-GB
          </a>
        </p>

        <p>
          5.3.2 Microsoft Internet Explorer:
          <span> </span>
          https://support.microsoft.com/enus/kb/278835
        </p>

        <p>
          5.3.3 Microsoft Edge:
          <span> </span>
          https://support.microsoft.com/engb/products/microsoft-edge
          <span> </span>
          (Please note that there are no specific instructions at this time, but Microsoft support
          will be able to assist)
        </p>

        <p>
          5.3.4 Safari (macOS):
          <span> </span>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://support.apple.com/kb/PH21411?viewlocale=en_GB&locale=en_"
          >
            https://support.apple.com/kb/PH21411?viewlocale=en_GB&locale=en_
          </a>
        </p>

        <p>
          5.3.5 Safari (iOS):
          <span> </span>
          <a target="_blank" rel="noreferrer" href="https://support.apple.com/en-gb/HT201265">
            https://support.apple.com/en-gb/HT201265
          </a>
        </p>

        <p>
          5.3.6 Mozilla Firefox:
          <span> </span>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://support.mozilla.org/en-US/kb/enable-anddisable-Cookies-website-preferences"
          >
            https://support.mozilla.org/en-US/kb/enable-anddisable-Cookies-website-preferences
          </a>
        </p>

        <p>
          5.3.7 Android:
          <span> </span>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform"
          >
            https://support.google.com/chrome/answer/95647?co=GENIE.Platform
          </a>
          <span> </span>
          (Please refer to your device's documentation for manufacturers' own browsers)
        </p>

        <h2>6. Changes to this Cookie Policy</h2>

        <p>
          6.1 We may alter this Cookie Policy at any time. Any such changes will become binding on
          you on your first use of Our Site after the changes have been made. You are therefore
          advised to check this page from time to time.
        </p>

        <p>
          6.2 In the event of any conflict between the current version of this Cookie Policy and any
          previous version(s), the provisions current and in effect shall prevail unless it is
          expressly stated otherwise.
        </p>

        <h2>7. Further Information</h2>

        <p>
          7.1 If you would like to know more about how We use Cookies, please contact Us at
          privacy@displaynote.com, by telephone on +44 (0) 2890 730480, or by post at DisplayNote
          Technologies Limited, Ground Floor, Concourse 3 Building, Queens Road, Belfast, BT3 9DT.
          7.2 For more information about privacy policy please click here.
        </p>
      </STCookies>
    </LayoutText>
  )
}
