import { HooksRender } from '@dn/hooks'
import { useRef } from 'react'

// ~~~~~~ Hook

export const useJoinedMobileFloatPanelCaptureShareSlidesSlidesScrollbarHeight = (
  panelElementRef: React.RefObject<HTMLDivElement | null>,
  ItemHeight: number,
) => {
  // ~~~~~~ Hooks

  const forceRerender = HooksRender.ForceRender.useHook()

  HooksRender.ForceRenderOnResizeWindow.useHook()

  HooksRender.ForceRenderOnResizeElement.useHook(panelElementRef)

  // ~~~~~~ State

  // - Refs

  const titleRef = useRef<HTMLDivElement>(null)

  const slidesSelectInfoRef = useRef<HTMLDivElement>(null)

  const selectActionRef = useRef<HTMLDivElement>(null)

  // ~~~~~~ Computed

  // - Scrollbar height calc

  const slidesPanelHeight = panelElementRef.current?.clientHeight

  const titleHeight = titleRef.current?.clientHeight

  const slidesSelectInfoHeight = slidesSelectInfoRef.current?.clientHeight

  const selectHeight = selectActionRef.current?.clientHeight || 0

  const minScrollbarHeight = ItemHeight + ItemHeight / 2

  const scrollbarHeight =
    slidesPanelHeight && titleHeight && slidesSelectInfoHeight
      ? slidesPanelHeight - slidesSelectInfoHeight - titleHeight - selectHeight - 8
      : minScrollbarHeight

  return {
    refs: {
      titleRef,
      slidesSelectInfoRef,
      selectActionRef,
    },

    scrollbarHeight,

    recalculateScrollbarHeight: forceRerender,
  } as const
}
