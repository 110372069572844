import React from 'react'

import { DialogBreakPoint } from '../../../constants/breakpoints'
import { Button } from '../../common/button/component'
import { Actions } from '../compound-dialog/actions/component'
import { Content } from '../compound-dialog/content/component'
import { genAnimatedDialog } from '../compound-dialog/dialog/component'
import { InfoBox } from '../../common/infos/box/component'
import { Trans } from '../../common/intl/trans'
import { LazySVG } from '../../svgs/lazy-svg/component'
import { BtnColorType } from '../../common/button/style'

// ~~~~~~ Types

type IntlValues = { [key: string]: number | string }

type Props = {
  Dialog: ReturnType<typeof genAnimatedDialog>

  icon?: {
    Icon: ReturnType<typeof LazySVG>
    color: ThemeColor
  }

  predefIcon?: 'info' | 'success' | 'error'

  title: IntlMsgId
  titleValues?: IntlValues

  extra?: any

  errors?: Infos[]
  contentText: IntlMsgId
  contentTextValues?: IntlValues

  isRunning: boolean
  doClose: number

  hideCloseButton?: true
  closeButtonIntl?: IntlMsgId
  closeButtonColorType?: BtnColorType

  okButtonIntl?: IntlMsgId
  okButtonColorType?: BtnColorType

  onEndDisappear: () => void
  onClickClose: () => void
  onClickConfirm: () => void
  onWillBeClosed?: () => void
}

// ~~~~~~ Component

export const DialogConfirmation: React.FC<Props> = ({
  Dialog,

  icon,
  predefIcon,

  title,
  titleValues,

  extra,

  errors,
  contentText,
  contentTextValues,

  hideCloseButton,
  closeButtonIntl,
  closeButtonColorType,

  okButtonIntl,
  okButtonColorType,

  isRunning,
  doClose,

  onEndDisappear,
  onClickClose,
  onClickConfirm,
  onWillBeClosed,
}) => {
  return (
    <Dialog
      data-testid="confirmation-dialog"
      icon={icon}
      predefIcon={predefIcon}
      stackedBtns={true}
      modalSize={DialogBreakPoint.XS}
      dialogTitle={title}
      dialogTitleValues={titleValues}
      closeDialog={doClose}
      endDisappear={onEndDisappear}
      onWillBeClosed={onWillBeClosed}
    >
      {/* Content */}

      <Content>
        {errors && errors.length ? (
          <InfoBox withIcon={true} $boxType="ERROR" infos={errors} />
        ) : undefined}

        <Trans id={contentText} values={contentTextValues} />
      </Content>

      {/* Actions */}

      <Actions>
        <div>{extra}</div>

        {hideCloseButton ? undefined : (
          <Button
            data-testid="close"
            intlId={closeButtonIntl ?? 'common.Cancel'}
            $colorType={closeButtonColorType || 'secondary'}
            disabled={false}
            $running={false}
            $active={true}
            onClick={onClickClose}
          />
        )}

        <Button
          data-testid="confirm"
          intlId={okButtonIntl || 'common.Confirm'}
          $colorType={okButtonColorType || 'primary'}
          disabled={isRunning}
          $running={isRunning}
          $active={!isRunning}
          onClick={onClickConfirm}
        />
      </Actions>
    </Dialog>
  )
}
