import { useRef } from 'react'
import { HooksRender } from '@dn/hooks'

// ~~~~~~ Hooks

export const useClassroomScrollbarHeight = (
  panelElementRef: React.RefObject<HTMLDivElement | null>,
  topElementRef: React.RefObject<HTMLDivElement | null>,
  panelMargin: number,
  Padding: number,
  ItemHeight: number,
) => {
  // ~~~~~~ Hooks

  const forceRerender = HooksRender.ForceRender.useHook()

  HooksRender.ForceRenderOnResizeWindow.useHook()

  HooksRender.ForceRenderOnResizeElement.useHook(topElementRef)

  // ~~~~~~ State

  // - Refs

  const titleRef = useRef<HTMLDivElement>(null)

  const classroomQttyRef = useRef<HTMLDivElement>(null)

  const actionRef = useRef<HTMLDivElement>(null)

  // ~~~~~~ Computed

  // - Scrollbar height calc

  const panelElHeight = panelElementRef.current?.clientHeight

  const topElHeight = topElementRef.current?.clientHeight

  const titleHeight = titleRef.current?.clientHeight

  const classroomQttyHeight = classroomQttyRef.current?.clientHeight || 0

  const actionHeight = actionRef.current?.clientHeight || 0

  const minScrollbarHeight = ItemHeight + ItemHeight / 2

  const scrollbarHeight =
    panelElHeight && topElHeight && titleHeight
      ? panelElHeight -
        panelMargin -
        topElHeight -
        classroomQttyHeight -
        titleHeight -
        actionHeight -
        Padding * 2
      : minScrollbarHeight

  return {
    refs: {
      titleRef,
      classroomQttyRef,
      actionRef,
    },

    scrollbarHeight,

    recalculateScrollbarHeight: forceRerender,
  } as const
}
