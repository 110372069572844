import { AnimatePresence } from 'framer-motion'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { Routes } from '../../../../constants/routes/routes'
import { StoreState } from '../../../../models/app/model'
import { UiOnboardingFieldsMR } from '../../../../models/bcast/ui-onboarding/model'
import { LazySVG } from '../../../svgs/lazy-svg/component'
import { Trans } from '../../intl/trans'
import { OnboardingPanelDone } from './done/component'
import { OnboardingPanelOption } from './option/component'
import { OnboardingPanelPercent } from './percent/component'
import { ClassName, STMotionOnboardingPanel } from './style'
import { ApiBcastUserSettingsAC } from '../../../../store/actions/api-bcast/user-settings/actions'

import ImageRocket from '../../../../imgs/webp/onboard-title-icon.webp'
import ImageHurra from '../../../../imgs/webp/hurra.webp'
import { useEffect } from 'react'

// ~~~~~~ Constants

const IconClose = LazySVG('icons/exit')

const validPaths = [Routes.Start, Routes.Starting, Routes.Started]

// ~~~~~~ Component

export const OnboardingPanel = () => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  const path = useLocation().pathname

  // ~~~~~~ State

  const currentUserId = useSelector((state: StoreState) => state.currentUser.id)

  const settings = useSelector((state: StoreState) => state.userSettings)

  const panelState = useSelector((state: StoreState) => state.uiOnboarding.panelState)

  const userSettings = useSelector((state: StoreState) => state.userSettings)

  // ~~~~~~ Computed

  const isValidPath = validPaths.includes(path as any)

  const isStartPath = path === Routes.Start

  const isCreateABcastUnlock = path === Routes.Start || path === Routes.Starting

  const isBroadcasting = path === Routes.Started

  const buttonShouldBeVisible =
    !userSettings.onboarding_is_completed &&
    (isStartPath || (isValidPath && userSettings.isOnboardingRunning))

  const showPanel = panelState === 'show' && buttonShouldBeVisible

  const isDone = userSettings.onboardingPercent === 100

  // ~~~~~~ Handlers

  function onClickClose() {
    dispatch(UiOnboardingFieldsMR.panelState.MC.change('hidden'))

    if (!isDone) return

    dispatch(
      ApiBcastUserSettingsAC.update(currentUserId, {
        onboarding_is_completed: true,
      }),
    )
  }

  // ~~~~~~ Effects

  // - Ensure show on hurra!

  useEffect(() => {
    if (
      !settings.isOnboardingRunning ||
      !settings.onboarding_create_a_bcast ||
      !settings.onboarding_change_source ||
      !settings.onboarding_share ||
      !settings.onboarding_live_draw ||
      !buttonShouldBeVisible ||
      panelState === 'show'
    ) {
      return
    }

    dispatch(UiOnboardingFieldsMR.panelState.MC.change('show'))

    //
  }, [
    buttonShouldBeVisible,
    dispatch,
    panelState,
    settings.isOnboardingRunning,
    settings.onboarding_change_source,
    settings.onboarding_create_a_bcast,
    settings.onboarding_live_draw,
    settings.onboarding_share,
  ])

  // - Ensure hide on end

  useEffect(() => {
    if (buttonShouldBeVisible || panelState === 'hidden') {
      return
    }

    dispatch(UiOnboardingFieldsMR.panelState.MC.change('hidden'))

    //
  }, [buttonShouldBeVisible, dispatch, panelState])

  // ~~~~~~ Render

  return (
    <AnimatePresence>
      {showPanel ? (
        <STMotionOnboardingPanel
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          {/* Close */}

          <div className={`${ClassName}--close`} onClick={onClickClose}>
            <IconClose size={13} />
          </div>

          {/* Content */}

          {isDone ? (
            <div className={`${ClassName}--content`}>
              <div className={`${ClassName}--content--done`}>
                {/* Icon */}

                <div className={`${ClassName}--content--done--icon`}>
                  <img src={ImageHurra} alt="hurra" />
                </div>

                {/* Title */}

                <div className={`${ClassName}--content--done--title`}>
                  <Trans id="common-components.onboarding.main-panel-hurra.Title" />
                </div>

                {/* Info */}

                <div className={`${ClassName}--content--done--info`}>
                  <Trans id="common-components.onboarding.main-panel-hurra.Louver1" />
                </div>
              </div>
            </div>
          ) : (
            <div className={`${ClassName}--content`}>
              {/* Title */}

              <div className={`${ClassName}--content--title`}>
                <Trans id="common-components.onboarding.main-panel.Title" />

                <img src={ImageRocket} alt="rocket" />
              </div>

              {/* Subtitle */}

              <div className={`${ClassName}--content--subtitle`}>
                <Trans id="common-components.onboarding.main-panel.Subtitle" />
              </div>

              {/* Percent */}

              <div className={`${ClassName}--content--percent`}>
                <OnboardingPanelPercent value={settings.onboardingPercent} />
              </div>

              {/* Options */}

              <div className={`${ClassName}--content--options`}>
                {/* Create a bcast */}

                <OnboardingPanelOption
                  intlId="common-components.onboarding.main-panel.options.CreateBcast"
                  status={
                    settings.onboarding_create_a_bcast
                      ? 'success'
                      : isCreateABcastUnlock
                        ? 'todo'
                        : 'lock'
                  }
                />

                {/* Change source */}

                <OnboardingPanelOption
                  intlId="common-components.onboarding.main-panel.options.ChangeSource"
                  status={
                    settings.onboarding_change_source
                      ? 'success'
                      : isBroadcasting && settings.onboarding_create_a_bcast
                        ? 'todo'
                        : 'lock'
                  }
                />

                {/* Share */}

                <OnboardingPanelOption
                  intlId="common-components.onboarding.main-panel.options.Share"
                  status={
                    settings.onboarding_share
                      ? 'success'
                      : isBroadcasting && settings.onboarding_change_source
                        ? 'todo'
                        : 'lock'
                  }
                />

                {/* Live draw */}

                <OnboardingPanelOption
                  intlId="common-components.onboarding.main-panel.options.LiveDraw"
                  status={
                    settings.onboarding_live_draw
                      ? 'success'
                      : isBroadcasting && settings.onboarding_share
                        ? 'todo'
                        : 'lock'
                  }
                />

                {/* Done */}

                <OnboardingPanelDone />

                {/* -- */}
              </div>
            </div>
          )}
        </STMotionOnboardingPanel>
      ) : undefined}
    </AnimatePresence>
  )
}
